import Methods from '@/api/methods'
export default {
  data() {
    const limitPerPages = [50, 100]
    return {
      items         : [],
      limitPerPages : limitPerPages,
      filter        : {
        areas               : [],
        category            : [],
        categoryExtension   : [],
        limit               : limitPerPages[0],
        initLimit           : limitPerPages[0]
      },
      errors                : [],
      filteredObject        : {},
      areaConstants         : [],
      categoryConstants     : [],
      craneCategoryIds      : [],
      categoryResultCount   : null,
      isMoreLimit           : false,
      itemsCount            : 0,
      showStockCraneFlag    : false
    }
  },
  created() {
    try {
      if (this.$route.query && this.$route.query.filter) {
        this.filter = Object.assign(this.filter, JSON.parse(decodeURIComponent(this.$route.query.filter)))
      } else if (this.$route.params.id) {
        this.filter.exhibitionItemNos = [this.$route.params.id]
      }
      if (!this.$route.params.id) {
        delete this.filter.exhibitionItemNos
        delete this.filter.recommending
        delete this.filter.newStock
      }
      if (this.extraFilter) {
        this.filter = Object.assign({}, this.filter, this.extraFilter)
      }
    } catch (e) {
      console.log(e)
    }
    if (this.$route.meta.enableQuery) {
      this.getItemSearchConstants()
    } else {
      this.searchItems()
    }
  },
  methods : {
    searchItems(showedItemNos = null) {
      this.$store.state.loading = true
      const params = this.getSearchItemsRequestParams(this.filter, showedItemNos)
      this.search(params).then(data => {
        this.$store.state.loading = false
        this.isMoreLimit = data.isMoreLimit
        this.itemsCount = data.count
        if (data.moreSearch) {
          Array.prototype.push.apply(this.items, data.items)
          this.filter.initLimit = this.items.length
        } else {
          this.items = data.items
          if (this.$route.params.id) {
            this.getCurrentItem()
          }
        }
        this.categoryResultCount = this.categoryConstants.map((row, index) => {
          const category = data.category_group.find(category => String(category.category_id) === String(this.categoryConstants[index].value1))
          return category ? category.count : 0
        })
        this.showStockCraneFlag = false
        data.category_group.forEach(group => {
          if (this.craneCategoryIds.includes(group.category_id)) {
            this.showStockCraneFlag = true
          }
        })

        this.filteredObject = JSON.parse(JSON.stringify(this.filter))
        if (this.$route.meta.enableQuery) {
          this.$routerGo(this.$route.name, null, {
            filter : encodeURIComponent(JSON.stringify(this.filter))
          })
        }
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    getFiltered(filter) {
      const filtered = []
      filtered.push(this.$t(this.parent.typeTag))
      if (filter.areas) {
        filter.areas.forEach((element, index) => {
          if (element) {
            filtered.push(this.areaConstants[index].value2)
          }
        })
      }
      if (filter.category) {
        filter.category.forEach((element, index) => {
          if (element) {
            filtered.push(this.categoryConstants[index].value2)
            filter.categoryExtension[index].forEach(row => {
              filtered.push(row)
            })
          }
        })
      }
      if (filter.searchKey) {
        filtered.push(filter.searchKey)
      }
      if (filter.startYear || filter.endYear) {
        filtered.push((filter.startYear || '') + '～' + (filter.endYear || ''))
      }
      if (filter.startPrice || filter.endPrice) {
        filtered.push((filter.startPrice ? filter.startPrice + this.$t('ITEM_SEARCH_PRICE_UNIT') : '') + '～' + (filter.endPrice ? filter.endPrice + this.$t('ITEM_SEARCH_PRICE_UNIT') : ''))
      }

      if (filter.favorite) {
        filtered.push(this.$t('ITEM_SEARCH_FAVORITE_LABEL'))
      }
      if (filter.bidding) {
        filtered.push(this.$t('ITEM_SEARCH_BIDDING_LABEL'))
      }
      if (filter.exceedingLowestPrice) {
        filtered.push(this.$t('ITEM_SEARCH_EXCEEDING_THE_LOWEST_BID_LABEL'))
      }
      if (filter.soldOut) {
        filtered.push(this.$t('ITEM_SEARCH_SOLD_OUT_LABEL'))
      }
      if (filter.recommending) {
        filtered.push(this.$t('ITEM_SEARCH_RECOMMEND_LABEL'))
      }
      if (filter.newStock) {
        filtered.push(this.$t('ITEM_SEARCH_NEW_LABEL'))
      }
      return filtered
    },
    getSearchItemsRequestParams(filter, showedItemNos = null) {
      const params = Object.assign({}, filter, {
        showedItemNos : showedItemNos,
        areas         : filter.areas.map((area, index) => area ? this.areaConstants[index].value1 : null).filter(row => row),
        startPrice    : Number(filter.startPrice) * 10000,
        endPrice      : Number(filter.endPrice) * 10000,
        limit         : showedItemNos ? filter.limit : filter.initLimit,
        category      : filter.category
          .map((row, index) => {
            if (row) {
              const array = this.filter.categoryExtension[index].map(ext => {
                return {
                  categoryNo : this.categoryConstants[index].value1,
                  extension  : ext
                }
              })
              return array.length > 0
                ? array
                : [
                  {
                    categoryNo : this.categoryConstants[index].value1
                  }
                ]
            } else {
              return null
            }
          })
          .filter(row => row)
          .reduce((a, b) => {
            const tmp = []
            Array.prototype.push.apply(tmp, a)
            Array.prototype.push.apply(tmp, b)
            return tmp
          }, [])
      })
      delete params.categoryExtension
      return params
    },
    getItemSearchConstants() {
      this.$store.state.loading = true
      Methods.apiExcute('public/get-item-search-constants', this.$i18n.locale).then(data => {
        this.$store.state.loading = false
        this.areaConstants = data.filter(constant => constant.key_string === 'AREA_CODE')
        this.categoryConstants = data.filter(constant => constant.key_string === 'CATEGORY')
        this.craneCategoryIds = data.find(constant => constant.key_string === 'CATEGORY_CRANE').value2.split(',').filter(element => element)
        this.categoryConstants = data.filter(constant => constant.key_string === 'CATEGORY')
        this.initSearch()
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    initSearch() {
      this.filter.areas = this.filter.areas && this.filter.areas.length > 0
        ? this.filter.areas
        : Array.from({length : this.areaConstants.length}, (value, index) => {
          return this.filter.areas.length > index ? this.filter.areas[index] : false
        })
      this.filter.category = this.filter.category && this.filter.category.length > 0
        ? this.filter.category
        : Array.from({length : this.categoryConstants.length}, (value, index) => {
          return this.craneCategoryIds.includes(this.categoryConstants[index].value1) ? this.filter.initCrane : this.filter.category.length > index ? this.filter.category[index] : this.filter.initCrane === false
        })
      this.filter.categoryExtension = this.filter.categoryExtension && this.filter.categoryExtension.length > 0
        ? this.filter.categoryExtension
        : Array.from({length : this.categoryConstants.length}, (value, index) => {
          return this.filter.categoryExtension.length > index ? this.filter.categoryExtension[index] : []
        })
      delete this.filter.initCrane
      this.searchItems()
    },
    updateViewAfterBid(bidList) {
      this.showBulkBiddingButton = false
      this.items.map(item => {
        const bid = bidList.find(bid => String(item.exhibition_item_no) === String(bid.exhibition_item_no))
        if (bid) {
          item.bid_status = Object.assign({}, item.bid_status, bid)
          if (this.removeBulkItemByItemNo) {
            this.removeBulkItemByItemNo(item.exhibition_item_no)
          }
          item.attention_info.bid_count += 1
        }
        if (item.changed) {
          this.showBulkBiddingButton = true
        }
        return item
      })
    },
    getCurrentItem() {
      this.itemIndex = this.items.findIndex(item => String(item.exhibition_item_no) === String(this.$route.params.id))
      if (this.itemIndex === -1) {
        alert('不正なURLです。')
        this.$routerGo(this.parent.searchPageRouterName, null, {filter : this.$route.query.filter})
      } else {
        this.item = this.items[this.itemIndex]
        this.inputBidPrice = this.getBidPrice()
        if (this.itemIndex === this.items.length - 1 && this.isMoreLimit) {
          this.searchItems(this.items.map(item => item.exhibition_item_no))
        }
        this.refreshItemForDetail()
      }
    }
  },
  watch : {
    '$i18n.locale'() {
      if (this.$route.meta.enableQuery) {
        this.getItemSearchConstants()
      } else {
        this.searchItems()
      }
    },
    '$route.query.filter'(value) {
      try {
        const newFilter = JSON.parse(decodeURIComponent(value))
        if (typeof newFilter.category === 'undefined') {
          this.filter = Object.assign({
            areas               : [],
            category            : [],
            categoryExtension   : [],
            limit               : this.limitPerPages[0],
            initLimit           : this.limitPerPages[0]
          }, JSON.parse(decodeURIComponent(value)))
          this.initSearch()
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
