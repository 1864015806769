<template>
<div class="machine-box-pickup">
  <h2>{{$t("TOP_PAGE_AUCTION_RECOMMEND_ITEMS_VIEW_HEADER")}}</h2>
  <div v-if="items.length == 0" class="matchH machine-item no-item">
    <p v-if="parent.exhibitionSchedules.filter(item => item.hold_status==2).length > 0" class="no-item-msg" v-html="$t('NO_RECOMMEND_ITEM_IN_SESSION_MESSAGE', {names: parent.exhibitionSchedules.filter(item => item.hold_status==2).map(item => item.exhibition_name).join(', ')})"></p>
    <p v-else-if="parent.exhibitionSchedules.filter(item => item.hold_status ===1).length > 0" class="no-item-msg" v-html="$t('NO_RECOMMEND_ITEM_SCHEDULED_MESSAGE', {start_date: parent.exhibitionSchedules.filter(item => item.hold_status ===1)[0].start_date})"></p>
    <p v-else class="no-item-msg" v-html="$t('NO_RECOMMEND_ITEM_NO_SCHEDULE_MESSAGE')"></p>
  </div>
  <div v-else class="matchH machine-item">
    <ul>
      <li v-for="item in items" :key="item.exhibition_item_no">
        <a @click="$routerGo(item.auction_classification === '1' ? $define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME : $define.PAGE_NAMES.TENDER_DETAIL_PAGE_NAME, item.exhibition_item_no, {filter : encodeURIComponent(JSON.stringify(filter))})">
          <figure>
            <img v-if="item.image && item.image.length > 0 && item.image.find(file => !file.postar_file_path && file.file_path)"  :src="'/'+encodeURIComponent(item.image.find(file => !file.postar_file_path && file.file_path).file_path)"  alt="">
            <img v-else src="@/assets/img/no_photo.jpg" alt="">
          </figure>
          <dl>
            <dt>{{item.free_field.MAKER ? item.free_field.MAKER : ''}}</dt>
            <dd>{{$t("TOP_PAGE_ITEM_MODEL_LABEL")}}：{{item.free_field.MODEL ? item.free_field.MODEL : ''}}</dd>
            <dd>{{$t("TOP_PAGE_ITEM_MODEL_SERIAL_NUMBER_LABEL")}}：{{item.free_field.ACTSERNO ? item.free_field.ACTSERNO : ''}}</dd>
            <dd>{{$t("TOP_PAGE_ITEM_MODEL_YEAR_LABEL")}}：{{item.free_field.YYYY ? item.free_field.YYYY : ''}}</dd>
          </dl>
          <p class="price">
            {{$t("TOP_PAGE_PRICE_CURRENCY_MARK")}}
            <span v-if="item.bid_status" class="member-only">{{item.price_display_flag != 0 ? item.bid_status.current_price.numberString() : 'ASK'}}</span>
            <span v-else class="member-only">{{item.price_display_flag != 0 ? item.free_field.NEWSPRC.numberString() : 'ASK'}}</span>
          </p>
        </a>
      </li>
    </ul>
  </div>
  <div class="machine-btn">
    <span>
      <a @click="$routerGo($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: false}))})" class="btn-main">
        {{$t("TOP_PAGE_LIST_OF_AUCTION_EXHIBITORS_BUTTON_PREFIX_LABEL")}}
        <br class="only_sp">
        {{$t("TOP_PAGE_LIST_OF_AUCTION_EXHIBITORS_BUTTON_SUBFIX_LABEL")}}
        <img src="@/assets/img/common/ic_arrow_white.svg" alt="矢印">
      </a>
      <a @click="$routerGo($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: true}))})" class="btn-crane">
        {{$t("TOP_PAGE_CRANE_LIST_BUTTON_PREFIX_LABEL")}}
        <br class="only_sp">
        {{$t("TOP_PAGE_CRANE_LIST_BUTTON_SUBFIX_LABEL")}}
        <img src="@/assets/img/common/ic_arrow_bgreen.svg" alt="矢印">
      </a>
    </span>
    <span>
      <a @click="$routerGo($define.PAGE_NAMES.TENDER_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: false}))})" class="btn-main">
        {{$t("TOP_PAGE_LIST_OF_TENDER_EXHIBITORS_BUTTON_PREFIX_LABEL")}}
        <br class="only_sp">
        {{$t("TOP_PAGE_LIST_OF_TENDER_EXHIBITORS_BUTTON_SUBFIX_LABEL")}}
        <img src="@/assets/img/common/ic_arrow_white.svg" alt="矢印">
      </a>
      <a @click="$routerGo($define.PAGE_NAMES.TENDER_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: true}))})" class="btn-crane">
        {{$t("TOP_PAGE_CRANE_LIST_BUTTON_PREFIX_LABEL")}}
        <br class="only_sp">
        {{$t("TOP_PAGE_CRANE_LIST_BUTTON_SUBFIX_LABEL")}}
        <img src="@/assets/img/common/ic_arrow_bgreen.svg" alt="矢印">
      </a>
    </span>
  </div>
</div>
</template>

<script>
import Methods from '@/api/methods'
import Filter from '../ItemList/Filter.js'
export default {
  props: {
    parent: {
      type: Object,
      default: Object
    },
    search: {
      type: Function,
      default: Object
    }
  },
  mixins: [Filter],
  data() {
    return {
      extraFilter: {
        initLimit: 12,
        recommending: true
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .matchH {
    &.no-item {
      height: calc(100% - 260px);
    }
  }
</style>

<style scoped src="@/assets/css/home.css">
</style>
<style scoped src="@/assets/css/set.css">
</style>
<style scoped src="@/assets/css/margin.css">
</style>
<style scoped src="@/assets/css/layout.css">
</style>
<style scoped src="@/assets/css/parts.css">
</style>
