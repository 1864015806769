import Methods from '@/api/methods'
import Base from '@/common/base'

export default {
  data() {
    return {
      errors        : [],
      inputBidPrice : this.getBidPrice()
    }
  },
  methods : {
    addPitch(pitch) {
      if (this.errorMessages && this.errorMessages.length > 0) {
        this.errorMessages.splice(0, this.errorMessages.length)
      }
      this.errors = []
      const newPrice = Number(this.inputBidPrice ? Base.localeString2Number(this.inputBidPrice) : parseInt(this.item.bid_status.current_price / this.item.bid_status.pitch_width, 10)) + (pitch / this.item.bid_status.pitch_width)
      this.inputBidPrice = this.bidPriceLocaleString(newPrice)
    },
    bidItemClick(typeTag) {
      if (typeTag === 'TENDER_PAGE_SEARCH_TYPE') {
        if (this.inputBidPrice === '') {
          this.bidItem()
        } else {
          this.openBidConfirmDialog(this.item.exhibition_item_no, this.inputBidPrice)
        }
      } else if (typeTag === 'AUCTION_PAGE_SEARCH_TYPE') {
        if (this.inputBidPrice === '' || this.inputBidPrice === '0') {
          this.bidItem()
        } else {
          this.openBidConfirmDialog(this.item.exhibition_item_no, this.inputBidPrice)
        }
      }
    },
    openBidConfirmDialog(itemNo, newBidPrice) {
      this.$emit('bidConfirm', itemNo, newBidPrice)
    },
    bidItem() {
      this.$store.state.loading = true
      this.errors = []
      const params = {
        bidItems : [
          {
            exhibitionItemNo : this.item.exhibition_item_no,
            bidPrice         : this.inputBidPrice === '' ? null : Base.localeString2Number(this.inputBidPrice) * this.item.bid_status.pitch_width
          }
        ]
      }
      Methods.apiExcute('private/bid-items', this.$i18n.locale, params).then(data => {
        this.$store.state.loading = false
        this.item.changed = false
        this.updateViewAfterBid(data.bidList.filter(bid => !bid.errorMessage))
        this.errors = data.bidList.filter(bid => bid.errorMessage)
        // 入札と取消が成功の場合にメッセージを表示する
        if (this.errors.length <= 0) {
          if (params.bidItems[0].bidPrice === 0) {
            // 取り消す
            this.errors = [
              {
                success      : true,
                errorMessage : this.$t('ITEM_ROW_CANCEL_SUCCESS_MESSAGE')
              }
            ]
          } else {
            // 入札
            this.errors = [
              {
                success      : true,
                errorMessage : this.$t('ITEM_ROW_BID_SUCCESS_MESSAGE')
              }
            ]
          }
        }
      })
        .catch(error => {
          this.$store.state.loading = false
          Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    favoriteItem(favorited) {
      this.$store.state.loading = true
      const params = {
        favorited          : favorited,
        exhibition_item_no : this.item.exhibition_item_no
      }
      this.favorite(params)
        .then(data => {
          this.$store.state.loading = false
          this.item.attention_info.is_favorited = !favorited
          this.item.attention_info.favorited_count += favorited ? -1 : 1
        })
        .catch(error => {
          this.$store.state.loading = false
          Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    getBidPrice() {
      const bidPrice = this.item && this.item.inputBidPrice ? this.item.inputBidPrice : this.item && this.item.bid_status && this.item.bid_status.bid_price ? (this.item.bid_status.bid_price / this.item.bid_status.pitch_width) : ''
      return this.bidPriceLocaleString(bidPrice)
    },
    reloadItemStatus() {
      this.$store.state.loading = true
      const params = {
        exhibitionItemNo : this.item.exhibition_item_no
      }
      Methods.apiExcute('private/reload-item-status', this.$i18n.locale, params).then(data => {
        this.$store.state.loading = false
        this.item = Object.assign(this.item, data)
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    // 値が「-」(数値以外)や空文字の場合は単位を表示しないようにする
    number2string(val, nullText = '', unit = '') {
      if (typeof val === 'undefined' || val === null || String(val).length === 0) {
        return nullText
      }
      return isNaN(val) ? String(val) : (val.numberString() + unit)
    },
    getAreaName(areaId) {
      const areas = this.areaConstants || this.parent.areaConstants
      if (areas) {
        const filtered_areas = areas.filter(x => x.value1 === areaId)
        return filtered_areas.length > 0 ? filtered_areas[0].value2 : ''
      }
      return ''
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      }
      return true
    },
    priceLocaleString(event) {
      const bidPrice = event.target.value
      if (!bidPrice || bidPrice === '') {
        return ''
      }
      if (Number(bidPrice) === 0) {
        return '0'
      }
      return this.bidPriceLocaleString(bidPrice)
    },
    bidPriceLocaleString(value) {
      const pitch_width = this.item && this.item.bid_status && this.item.bid_status.pitch_width ? this.item.bid_status.pitch_width : 0
      const num = Base.localeString2Number(value) * pitch_width
      const commaPrice = num.toLocaleString()
      return commaPrice.substring(0, (commaPrice.length - pitch_width.numberString().substring(1).length))
    },
    bidConfirmPrice(value) {
      const pitch_width = this.item && this.item.bid_status && this.item.bid_status.pitch_width ? this.item.bid_status.pitch_width : 0
      const num = Base.localeString2Number(value) * pitch_width
      const commaPrice = num.toLocaleString()
      return commaPrice
    }
  }
}
