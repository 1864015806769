<template>
  <div id="auction-schedule" class="info-box-bid">
    <h2>{{$t("TOP_PAGE_AUCTION_SCHEDULE_VIEW_HEADER")}}</h2>
    <div class="info-item">
      <a class="item-tag" v-for="item in parent.exhibitionSchedules" :key="item.exhibition_no">
        <em v-if="item.hold_status==1" class="cate-now">{{$t("TOP_PAGE_AUCTION_STATUS_AUCTION_WILL_BE_HELD_LABEL")}}</em>
        <em v-else-if="item.hold_status==2" class="cate-hold">{{$t("TOP_PAGE_AUCTION_STATUS_AUCTION_IN_SESSION_LABEL")}}</em>
        <em v-else class="cate-fin">{{$t("TOP_PAGE_AUCTION_STATUS_AUCTION_ENDED_LABEL")}}</em>
        {{item.exhibition_name}}
        <span class="bid-day">{{item.start_date | moment($t('DATE_FORMAT'))}}～{{item.end_date | moment($t('DATE_FORMAT'))}}</span>
      </a>
    </div>
  </div>
</template>

<script>
import Methods from '@/api/methods'
export default {
  props: {
    parent: {
      type: Object,
      default: Object
    }
  },
  data() {
    return {
      scripts: [
        'tab-hash.js'
      ]
    }
  },
  created() {
    this.getExhibitionSchedules()
  },
  methods: {
    getExhibitionSchedules() {
      this.$store.state.loading = true
      Methods.apiExcute('public/get-exhibition-schedules', this.$i18n.locale).then(data => {
        this.$store.state.loading = false
        this.parent.exhibitionSchedules = data
        Methods.addExternalScirpts(this.scripts)
      }).catch((error) => {
        this.$store.state.loading = false
        this.errors = Methods.parseHtmlResponseError(this.$router, error)
      })
    }
  },
  watch : {
    '$i18n.locale' () {
      this.getExhibitionSchedules()
    }
  }
}
</script>
<style scoped lang="scss">
  .info-box-bid {
    .item-tag {
      cursor: default;
      color: black;
    }
    .info-item {
      @media only screen and (min-width: 768px) {
        max-height: 180px;
        overflow: auto;
      }

      @media only screen and (max-width: 767px) {
        max-height: 360px;
        overflow: auto;
      }
    }
  }
</style>

<style scoped src="@/assets/css/home.css">
</style>
<style scoped src="@/assets/css/set.css">
</style>
<style scoped src="@/assets/css/margin.css">
</style>
<style scoped src="@/assets/css/layout.css">
</style>
<style scoped src="@/assets/css/parts.css">
</style>
