<template>
<main id="main">
  <section id="machine">
    <a v-if="!$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)" class="btn-m-login" @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME)">{{$t("PAGE_LOGIN")}}<img src="@/assets/img/common/ic_arrow_bgreen.svg" alt="矢印"></a>
    <div class="wrap1160 machine-wrap">
      <RecommendItems :parent="$data" :search="searchAuctionItems"/>
      <NewStocks :parent="$data" :search="searchStockItems"/>
    </div>
  </section>

  <section id="info" class="bgCoCor">
    <div class="wrap1160">
      <Schedules :parent="$data"/>
      <Notices :parent="$data"/>
    </div>
  </section>

  <section id="first" class="wrap1160">
    <div class="sec-first bgLGray">
      <h2 @click="$routerGo($define.PAGE_NAMES.GUILD_PAGE_NAME)">{{$t("TOP_PAGE_FOR_NEWBIE_LABEL")}}</h2>
      <a @click="$routerGo($define.PAGE_NAMES.GUILD_PAGE_NAME)"><img class="first-mark" src="@/assets/img/home/ic_first.png" alt="はじめての方へ">{{$t("TOP_PAGE_AUCTION_GUIDE_LINK_LABEL")}}<img class="arrow" src="@/assets/img/common/ic_arrow_white.svg" alt="矢印"></a>
    </div>
  </section>
</main>
</template>

<script>
import Methods from '@/api/methods'
import RecommendItems from '../components/Top/RecommendItems'
import NewStocks from '../components/Top/NewStocks'
import Notices from '../components/Top/Notices'
import Schedules from '../components/Top/Schedules'
import { Icon } from '@iconify/vue2'
export default {
  components: {
    Icon,
    RecommendItems,
    NewStocks,
    Notices,
    Schedules
  },
  data() {
    return {
      exhibitionSchedules: []
    }
  },
  methods: {
    searchAuctionItems (params) {
      return Methods.apiExcute('public/search-auction-items', this.$i18n.locale, params)
    },
    searchStockItems (params) {
      return Methods.apiExcute('public/search-stock-items', this.$i18n.locale, params)
    }
  }
}
</script>
<style lang="scss">
  #main #machine {
    background-size: 100% !important;
  }
  #main #first .sec-first h2 {
    cursor: pointer;
  }
</style>
<style scoped src="@/assets/css/home.css">
</style>
<style scoped src="@/assets/css/set.css">
</style>
<style scoped src="@/assets/css/margin.css">
</style>
<style scoped src="@/assets/css/layout.css">
</style>
<style scoped src="@/assets/css/parts.css">
</style>
