<template>
<main id="main">
  <div class="wrap1160">
    <section id="entry-info">
      <dl>
        <dt v-html="$t('MEMBER_REQUEST_DOCUMENT_EXPLANATION_MESSAGE')">
        </dt>
        <dd>
          <p class="jp-document" v-html="$t('MEMBER_REQUEST_DOCUMENT_TITLE')"></p>
          <ol>
            <li
              v-for="(constant, index) in parent.samleFiles.filter(x => isJapanView() || x.sort_order !== 1)"
              :key="index"
              :style="constant.sort_order === 2 ? 'width: 380px;' : 'width: 300px;'"
            >
              <a :href="'#modal-open-' + (constant.sort_order ? constant.sort_order : (index + 1))">
                <p class="entry-info-item">
                  <span>{{index+1}}</span>
                  <label class="bold-text" v-html="constant.value1"></label>
                </p>
                <img :src="'./' + constant.file_url" alt="" style="max-height:200px">
                <p v-if="isJapanView() || constant.sort_order !== 2" class="kome entry-info-note">{{constant.value2}}</p>
              </a>
            </li>
          </ol>
          <div class="entry-info-btn">
            <a :href="parent.formConstant.file_url" target="_blank" class="btnBsc-DL">{{$t('MEMBER_REQUEST_DOWNLOAD_SAMPLE_DOCUMENT_BUTTON_LABEL')}}<img src="@/assets/img/common/ic_dl.svg" alt=""></a>
          </div>
        </dd>
      </dl>
      <p v-if="isJapanView()" class="entry-info-att" v-html="$t('MEMBER_REQUEST_FORM_CAUTION_MESSAGE')"></p>
      <div class="remodal" :data-remodal-id="'modal-open-' + (parent.sampleFile1 && parent.sampleFile1.sort_order ? parent.sampleFile1.sort_order : 1)">
        <button data-remodal-action="close" class="remodal-close"></button>
        <img :src="'./' + parent.sampleFile1.file_url" alt="">
      </div>
      <div class="remodal" :data-remodal-id="'modal-open-' + (parent.sampleFile2 && parent.sampleFile2.sort_order ? parent.sampleFile2.sort_order : 2)">
        <button data-remodal-action="close" class="remodal-close"></button>
        <img :src="'./' + parent.sampleFile2.file_url" alt="">
      </div>
      <div class="remodal" :data-remodal-id="'modal-open-' + (parent.sampleFile3 && parent.sampleFile3.sort_order ? parent.sampleFile3.sort_order : 3)">
        <button data-remodal-action="close" class="remodal-close"></button>
        <img :src="'./' + parent.sampleFile3.file_url" alt="">
      </div>
    </section>

    <section id="entry-form" class="bgLGray">
      <form>
        <p class="entry-form-info" v-html="$t('MEMBER_REQUEST_FORM_ENTRY_FORM_INFO_MESSAGE')"></p>
        <table class="tbl-entry">
          <tbody>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_COUNTRY_LABEL')"></th>
              <td>
                <div class="select-style">
                  <select required v-model="parent.registerData.countryCode" v-bind:class="{'err': errors.countryCode}" ref="countryCode">
                    <option v-for="constant in parent.constants.filter(constant => constant.key_string == 'COUNTRY_CODE')" :value="constant.value1" :label="constant.value2" selected="selected" :key="constant.value1">{{constant.value2}}</option>
                  </select>
                  <div class="error-msg">
                    <p class="err-txt">{{errors.countryCode}}</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_COMPANY_NAME_LABEL')"></th>
              <td>
                <input type="text" class="iptW-M" required v-model="parent.registerData.companyName" v-bind:class="{'err': errors.companyName}" @keydown="errors.companyName=null" ref="companyName">
                <div class="error-msg">
                  <p class="err-txt">{{errors.companyName}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_REPRESENTATIVE_DIRECTOR_LABEL')"></th>
              <td>
                <input type="text" class="iptW-M" v-model="parent.registerData.representativeDirector" v-bind:class="{'err': errors.representativeDirector}" @keydown="errors.representativeDirector=null" ref="representativeName">
                <div class="error-msg">
                  <p class="err-txt">{{errors.representativeDirector}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_BRANCH_OFFICE_LABEL')"></th>
              <td>
                <input type="text" class="iptW-M" v-model="parent.registerData.branchOffice" v-bind:class="{'err': errors.branchOffice}" @keydown="errors.branchOffice=null" ref="branchOffice">
                <div class="error-msg"><p class="err-txt">{{errors.branchOffice}}</p></div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_USER_NAME_LABEL')"></th>
              <td>
                <div class="ipt-wrap">
                  <input type="text" class="iptW-M" required v-model="parent.registerData.emailUserName" v-bind:class="{'err': errors.emailUserName}" @keydown="errors.emailUserName=null" ref="name">
                  <div class="error-msg">
                    <div class="error-center">
                      <span v-if="isJapanView()" class="ipt-rule" v-html="$t('MEMBER_REQUEST_FORM_FULL_WIDTH_LABEL')"></span>
                      <span v-else class="ipt-rule" v-html="$t('MEMBER_REQUEST_FORM_FULL_WIDTH_ALPHANUMERICAL_LABEL')"></span>
                      <p class="err-txt">{{errors.emailUserName}}</p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>{{$t('MEMBER_REQUEST_FORM_POST_CODE_LABEL')}}<em class="req" v-if="isJapanView()">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em></th>
              <td>
                <div class="ipt-wrap">
                  <input type="text" class="ime-dis iptW-S" :placeholder="$t('MEMBER_REQUEST_FORM_POST_CODE_PLACEHOLDER')" v-model="parent.registerData.postCode" @keydown="errors.postCode=null" required v-bind:class="{'err': errors.postCode}" ref="postCode">
                  <input v-if="isJapanView()" class="ipt-btn-gray zip-search" type="button" :value="$t('MEMBER_REQUEST_FORM_SEARCH_POST_CODE_BUTTON_LABEL')" @click="searchPostCode">
                  <div class="error-msg">
                    <p class="err-txt">{{errors.postCode}}</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="isJapanView()">
              <th>{{$t('MEMBER_REQUEST_FORM_PREFECTURES_LABEL')}}<em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em></th>
              <td>
                <input type="text" class="iptW-M" :placeholder="$t('MEMBER_REQUEST_FORM_PREFECTURES_PLACEHOLDER')" required v-model="parent.registerData.state" v-bind:class="{'err': errors.state}" @keydown="errors.state=null" ref="state">
                <div class="error-msg">
                  <p class="err-txt">{{errors.state}}</p>
                </div>
              </td>
            </tr>
            <tr v-if="isJapanView()">
              <th>{{$t('MEMBER_REQUEST_FORM_MUNICIPALITIES_LABEL')}}<em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em></th>
              <td>
                <input type="text" class="iptW-M" :placeholder="$t('MEMBER_REQUEST_FORM_MUNICIPALITIES_PLACEHOLDER')" required v-model="parent.registerData.city" v-bind:class="{'err': errors.city}" @keydown="errors.city=null" ref="city">
                <div class="error-msg">
                  <p class="err-txt">{{errors.city}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <th>{{isJapanView() ? $t('MEMBER_REQUEST_FORM_LOCATION_LABEL') : $t('MEMBER_REQUEST_FORM_LOCATION_LABEL_EN')}}<em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em></th>
              <td>
                <input type="text" class="iptW-M" :placeholder="$t('MEMBER_REQUEST_FORM_LOCATION_PLACEHOLDER')" required v-model="parent.registerData.address" v-bind:class="{'err': errors.address}" @keydown="errors.address=null" ref="address">
                <div class="error-msg">
                  <p class="err-txt">{{errors.address}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_TEL_LABEL')"></th>
              <td>
                <div class="ipt-wrap">
                  <input type="text" class="ime-dis iptW-M" :placeholder="$t('MEMBER_REQUEST_FORM_PHONE_NUMBER_PLACEHOLDER')" required v-model="parent.registerData.tel" v-bind:class="{'err': errors.tel}" @keydown="errors.tel=null" ref="tel">
                  <div class="error-msg">
                    <div class="error-center">
                      <span class="ipt-rule" v-html="$t('MEMBER_REQUEST_FORM_FULL_WIDTH_NUMBERS_LABEL')"></span>
                      <p class="err-txt">{{errors.tel}}</p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_FAX_LABEL')"></th>
              <td>
                <div class="ipt-wrap">
                  <input type="text" class="ime-dis iptW-M" :placeholder="$t('MEMBER_REQUEST_FORM_FAX_NUMBER_PLACEHOLDER')" v-model="parent.registerData.fax" v-bind:class="{'err': errors.fax}" @keydown="errors.fax=null" ref="fax">
                  <div class="error-msg">
                    <div class="error-center">
                      <span class="ipt-rule" v-html="$t('MEMBER_REQUEST_FORM_FULL_WIDTH_NUMBERS_LABEL')"></span>
                      <p class="err-txt">{{errors.fax}}</p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_EMAIL_LABEL')"></th>
              <td>
                <div class="ipt-wrap">
                  <input type="email" class="ime-dis iptW-M" required v-model="parent.registerData.email" v-bind:class="{'err': errors.email}" @keydown="errors.email=null" ref="email">
                  <div class="error-msg">
                    <div class="error-center">
                      <span class="ipt-rule" v-html="$t('MEMBER_REQUEST_FORM_FULL_WIDTH_ALPHANUMERICAL_LABEL')"></span>
                      <p class="err-txt">{{errors.email}}</p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_CONFIRM_EMAIL_LABEL')"></th>
              <td>
                <input type="email" class="ime-dis iptW-M" required v-model="parent.confirmEmail" v-bind:class="{'err': errors.confirmEmail}" @keydown="errors.confirmEmail=null" ref="confirmEmail">
                <div class="error-msg">
                  <p class="err-txt">{{errors.confirmEmail}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_PASSWORD_LABEL')"></th>
              <td>
                <input :type="passwordType" class="ime-dis iptW-M password" required v-model="parent.registerData.password" v-bind:class="{'err': errors.password}" @keydown="errors.password=null" ref="password" autocomplete="new-password">
                <span @click="showHidePassword(2)" class="show-password"><Icon :icon="passwordType === 'password' ? 'akar-icons:eye' : 'akar-icons:eye-slashed'" style="vertical-align: middle;"/></span>
                <div class="error-msg">
                  <p class="err-txt">{{errors.password}}</p>
                </div>
              </td>
            </tr>
            <tr class="att-use">
              <th>&nbsp;</th>
              <td>
                <div class="file-up-att" v-html="$t('MEMBER_REQUEST_FORM_PASSWORD_RULE')"></div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_CONFIRM_PASSWORD_LABEL')"></th>
              <td>
                <input :type="passwordConfirmType" class="ime-dis iptW-M password" required v-model="parent.confirmPassword" v-bind:class="{'err': errors.confirmPassword}" @keydown="errors.confirmPassword=null" ref="confirmPassword" autocomplete="new-password">
                <span @click="showHidePassword(3)" class="show-password"><Icon :icon="passwordConfirmType === 'password' ? 'akar-icons:eye' : 'akar-icons:eye-slashed'" style="vertical-align: middle;"/></span>
                <div class="error-msg">
                  <p class="err-txt">{{errors.confirmPassword}}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="caution-header">
          <div class="header" v-html="$t('MEMBER_REQUEST_FORM_FILE_UPLOAD_CAUTION_HEADER')"></div>
          <div class="requirement" v-html="$t('MEMBER_REQUEST_FORM_FILE_UPLOAD_CAUTION_MESSAGE')"></div>
        </div>

        <table class="tbl-entry">
          <tbody>
            <tr v-if="isJapanView()">
              <th class="file-label" v-html="$t('MEMBER_REQUEST_FORM_ANTIQUE_FILE_LABEL')"></th>
              <td>
                <div class="ipt-file ipt-file-box" v-for="(file, index) in parent.antiqueFiles">
                  <label @click="parent.antiqueFiles.splice(index, 1)">{{$t('MEMBER_REQUEST_FORM_FILE_DELETE_BUTTON_LABEL')}}</label>
                  <p class="ipt-file-txt">{{file.name}}</p>
                </div>
                <div class="ipt-file ipt-file-box" v-if="parent.antiqueFiles.length < 5">
                  <label v-bind:class="{'err': errors.antiqueFilePath}" @click="errors.antiqueFilePath=null"  ref="antiqueFilePath">
                    <input type="file" required @change="addAntiqueFile">{{$t('MEMBER_REQUEST_FORM_FILE_SELECT_BUTTON_LABEL')}}
                  </label>
                  <p class="ipt-file-txt" v-html="$t('MEMBER_REQUEST_FORM_FILE_UNSELECTED_MESSAGE')"></p>
                  <p class="err-txt">{{errors.antiqueFilePath}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <th class="file-label" v-html="$t('MEMBER_REQUEST_FORM_EXPORT_FILE_LABEL')"></th>
              <td>
                <div class="ipt-file ipt-file-box" v-for="(file, index) in parent.exportFiles">
                  <label @click="parent.exportFiles.splice(index, 1)">{{$t('MEMBER_REQUEST_FORM_FILE_DELETE_BUTTON_LABEL')}}</label>
                  <p class="ipt-file-txt">{{file.name}}</p>
                </div>
                <div class="ipt-file ipt-file-box" v-if="parent.exportFiles.length < 1">
                  <label v-bind:class="{'err': errors.exportFilePath}" @click="errors.exportFilePath=null"  ref="exportFilePath">
                    <input type="file" required @change="addExportFile">{{$t('MEMBER_REQUEST_FORM_FILE_SELECT_BUTTON_LABEL')}}
                  </label>
                  <p class="ipt-file-txt" v-html="$t('MEMBER_REQUEST_FORM_FILE_UNSELECTED_MESSAGE')"></p>
                  <p class="err-txt">{{errors.exportFilePath}}</p>
                </div>
              </td>
            </tr>
            <tr class="att-use" v-if="isJapanView() && parent.sampleFile2 && parent.sampleFile2.value2">
              <th>&nbsp;</th>
              <td>
                <div class="file-up-att"
                  v-html='("<p class=\"kome\" style=\"color:#f00;\">" + parent.sampleFile2.value2 + "</p>")'></div>
              </td>
            </tr>
            <tr>
              <th class="file-label" v-html="$t('MEMBER_REQUEST_FORM_NAME_CARD_FILE_LABEL')"></th>
              <td>
                <div class="ipt-file ipt-file-box" v-for="(file, index) in parent.nameCardFiles">
                  <label @click="parent.nameCardFiles.splice(index, 1)">{{$t('MEMBER_REQUEST_FORM_FILE_DELETE_BUTTON_LABEL')}}</label>
                  <p class="ipt-file-txt">{{file.name}}</p>
                </div>
                <div class="ipt-file ipt-file-box" v-if="parent.nameCardFiles.length < 5">
                  <label v-bind:class="{'err': errors.nameCardFilePath}" @click="errors.nameCardFilePath=null"  ref="nameCardFilePath">
                    <input type="file" required @change="addNameCardFile">{{$t('MEMBER_REQUEST_FORM_FILE_SELECT_BUTTON_LABEL')}}
                  </label>
                  <p class="ipt-file-txt" v-html="$t('MEMBER_REQUEST_FORM_FILE_UNSELECTED_MESSAGE')"></p>
                  <p class="err-txt">{{errors.nameCardFilePath}}</p>
                </div>
              </td>
            </tr>
            <tr class="att-use" v-if="!isJapanView()">
              <th>&nbsp;</th>
              <td>
                <div class="file-up-att" v-html="$t('MEMBER_REQUEST_FORM_FILE_UPLOAD_CAUTION_NONJP')"></div>
              </td>
            </tr>
            <div class="caution-header"></div>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_AGREE_LABEL')"></th>
              <td>
                <label>
                  <input name="rule" v-model="checkBox.rule" type="checkbox" class="checkbox-input" required>
                  <span class="checkbox-parts" v-html="$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL')"></span>
                </label>
              </td>
            </tr>
            <tr class="att-use">
              <th>&nbsp;</th>
              <td>
                <p class="privacy-link">
                  {{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE1')}}
                  <a @click="$routerGoNewWindow($define.PAGE_NAMES.PRIVACY_PAGE_NAME, null, {lang: $i18n.locale})">{{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE2')}}</a>
                  {{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE3')}}
                </p>
              </td>
            </tr>

            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_MEMBERSHIP_AGREE_LABEL')"></th>
              <td>
                <label>
                  <input name="membership" v-model="checkBox.membership" type="checkbox" class="checkbox-input" required>
                  <span class="checkbox-parts" v-html="$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL')"></span>
                </label>
              </td>
            </tr>
            <tr class="att-use">
              <th>&nbsp;</th>
              <td>
                <p class="privacy-link">
                  {{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE1')}}
                  <a
                    @click="goToPdfViewer(parent.membershipTerms.file_url)"
                  >{{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE2_1')}}</a>
                  {{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE3')}}
                </p>
              </td>
            </tr>

          </tbody>
        </table>
        <div class="btn-form">
          <input type="button" id="sbm-login" :value="$t('MEMBER_REQUEST_FORM_SUBMIT_LABEL')" :disabled="isButtonDisabled" @click="validate">
        </div>
      </form>
    </section>
  </div>
</main>
</template>

<script>
import Methods from '@/api/methods';
import commonMixin from '@/common/commonMixin';
import {Icon} from '@iconify/vue2';
import RegisterLogic from './RegisterLogic.js';
export default {
  components : {
    Icon
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    }
  },
  data() {
    return {
      scripts  : ['remodal.min.js'],
    }
  },
  mixins  : [RegisterLogic, commonMixin],
  methods : {
    request(params) {
      return Methods.apiExcute('request-member', this.$i18n.locale, params)
    },
    extraValidate() {
      if (this.parent.registerData.password !== this.parent.confirmPassword) {
        this.errors.confirmPassword = this.$t('PASSWORD_CONFIRM_ERROR_MESSAGE')
      }
      if (this.parent.registerData.email !== this.parent.confirmEmail) {
        this.errors.confirmEmail = this.$t('MAIL_CONFIRM_ERROR_MESSAGE')
      }
    },
  },
  computed : {
    isButtonDisabled() {
      return !this.checkBox.rule || !this.checkBox.membership
    }
  }
}
</script>

<style scoped lang="scss">
  .ipt-file-box {
    margin-bottom: 10px;
  }
  @media only screen and (min-width: 768px) {
    p.err-txt {
      position: absolute;
      top: 50%;
      left: 450px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
      max-width: 290px;
    }
  }
  @media only screen and (max-width: 767px) {
    p.err-txt {
        position: static;
        -webkit-transform: none;
        transform: none;
        max-width: 100%;
        margin-top: 5px;
    }
  }

  .with-int-no {
    width: 305px !important;
  }
  select.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  label.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  .bold-text {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
</style>
<style lang="scss">
  .btnBsc-DL {
    img {
      margin: 0 20px !important;
    }
  }
  .remodal-wrapper:after {
    vertical-align: middle;
  }
  .fontB {
    font-weight: 700!important;
  }
  p.kome {
    margin: 0 !important;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    font-style: normal;
    font-weight: normal;
    text-align: left;
    text-indent: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
</style>
<style lang="scss">
  @media only screen and (min-width: 768px) {
    #main #entry-form table.tbl-entry th {
      width: 246px !important;
    }
    .file-label {
      padding-left: 25px !important;
    }
  }
  .caution-header {
    margin-top: 20px !important;
    margin-bottom: 15px !important;
  }
  .caution-header .header {
    font-size: 16px;
    font-weight: 700;
    vertical-align: middle;
  }
  @media only screen and (min-width: 768px) {
    .caution-header .requirement {
      padding-left: 25px;
    }
  }
  @media only screen and (max-width: 767px) {
    .caution-header .requirement {
      padding-left: auto;
    }
  }
</style>
<style scoped src="@/assets/css/login.css">
</style>
<style scoped src="@/assets/css/set.css">
</style>
<style scoped src="@/assets/css/margin.css">
</style>
<style scoped src="@/assets/css/layout.css">
</style>
<style scoped src="@/assets/css/parts.css">
</style>
<style src="@/assets/css/entry.css">
</style>
<style src="@/assets/css/remodal.css">
</style>
<style scoped src="@/assets/css/remodal-default-theme.css">
</style>
