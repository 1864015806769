<template>
<div>
  <Register v-if="editFlag" :parent="$data" />
  <Comfirm v-else :parent="$data" />
</div>
</template>

<script>
import Methods from '@/api/methods';
import Comfirm from '../../components/ChangeInfo/Comfirm';
import Register from '../../components/ChangeInfo/Register';
export default {
  components : {
    Comfirm,
    Register
  },
  data() {
    return {
      mode            : 'ChangeInfo',
      registerData    : {},
      constants       : [],
      memberId        : null,
      editFlag        : true,
      selectedCountry : {},
      exportFiles     : [],
      antiqueFiles    : [],
      nameCardFiles   : [],
      confirmPassword : null,
      membershipTerms : {},
    }
  },
  methods : {
    getContactConstants() {
      this.$store.state.loading = true
      Methods.apiExcute('private/get-change-info-constants', this.$i18n.locale).then(data => {
        this.$store.state.loading = false
        this.constants = data.constants

        this.membershipTerms = this.constants.find(constant => constant.key_string === 'MEMBERSHIP_TERMS') || {}

        this.memberId = data.memberId
        if (data.member) {
          this.registerData = Object.assign({}, data.member, this.registerData)
        }
        this.exportFiles = data.member.exportFilePath
          ? [].concat(data.member.exportFilePath).map(x => ({key : x}))
          : []
        this.exportFilePath = data.member.exportFilePath ? [].concat(data.member.exportFilePath) : []
        this.antiqueFiles = data.member.antiqueFilePath ? [].concat(data.member.antiqueFilePath).map(x => ({key : x})) : []
        this.antiqueFilePath = data.member.antiqueFilePath ? [].concat(data.member.antiqueFilePath) : []
        this.nameCardFiles = data.member.nameCardFilePath ? [].concat(data.member.nameCardFilePath).map(x => ({key : x})) : []
        this.nameCardFilePath = data.member.nameCardFilePath ? [].concat(data.member.nameCardFilePath) : []
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    }
  },
  created() {
    this.getContactConstants()
  },
  watch : {
    '$i18n.locale'() {
      this.getContactConstants()
    },
    registerData(value) {
      if (Object.keys(value).length === 0) {
        this.getContactConstants()
        this.confirmPassword = null
      }
    }
  }
}
</script>
