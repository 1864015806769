<template>
<main id="main" class="comp">
  <div class="wrap1160">
    <p class="comp-msg" v-html="$t('MEMBER_REQUEST_CONFIRM_EXPLANATION_MESSAGE')"></p>
    <section id="entry-form" class="bgLGray">
      <form>
        <table class="tbl-entry">
          <tbody>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_COUNTRY_LABEL')"></th>
              <td>
                {{parent.constants.find(constant => constant.key_string == 'COUNTRY_CODE' && constant.value1  === parent.registerData.countryCode).value2}}
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_COMPANY_NAME_LABEL')"></th>
              <td>
                {{parent.registerData.companyName}}
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_REPRESENTATIVE_DIRECTOR_LABEL')"></th>
              <td>
                {{parent.registerData.representativeDirector}}
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_BRANCH_OFFICE_LABEL')"></th>
              <td>
                {{parent.registerData.branchOffice}}
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_USER_NAME_LABEL')"></th>
              <td>
                {{parent.registerData.emailUserName}}
              </td>
            </tr>
            <tr v-if="parent.registerData.postCode">
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_POST_CODE_LABEL')"></th>
              <td>
                {{parent.registerData.postCode}}
              </td>
            </tr>
            <tr v-if="parent.registerData.state">
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_PREFECTURES_LABEL')"></th>
              <td>
                {{parent.registerData.state}}
              </td>
            </tr>
            <tr v-if="parent.registerData.city">
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_MUNICIPALITIES_LABEL')"></th>
              <td>
                {{parent.registerData.city}}
              </td>
            </tr>
            <tr v-if="parent.registerData.address">
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_LOCATION_LABEL')"></th>
              <td>
                {{parent.registerData.address}}
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_TEL_LABEL')"></th>
              <td>
                {{parent.registerData.tel}}
              </td>
            </tr>
            <tr v-if="parent.registerData.fax">
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_FAX_LABEL')"></th>
              <td>
                {{parent.registerData.fax}}
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_EMAIL_LABEL')"></th>
              <td>
                {{parent.registerData.email}}
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_CONFIRM_EMAIL_LABEL')"></th>
              <td>
                {{parent.confirmEmail}}
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_PASSWORD_LABEL')"></th>
              <td>
                ********
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_CONFIRM_PASSWORD_LABEL')"></th>
              <td>
                ********
              </td>
            </tr>
            <tr v-if="parent.selectedCountry.value1 == 'JP'">
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_ANTIQUE_FILE_LABEL')"></th>
              <td>
                <div v-for="file in (parent.registerData.antiqueFilePath || [])">{{file.replace(/^.*[\\\/]/, '')}}</div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_EXPORT_FILE_LABEL')"></th>
              <td>
                <div v-for="file in (parent.registerData.exportFilePath || [])">{{file.replace(/^.*[\\\/]/, '')}}</div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_NAME_CARD_FILE_LABEL')"></th>
              <td>
                <div v-for="file in (parent.registerData.nameCardFilePath || [])">{{file.replace(/^.*[\\\/]/, '')}}</div>
              </td>
            </tr>
            <br />
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_AGREE_LABEL')"></th>
              <td v-html="$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL')"></td>
            </tr>
            <br />
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_MEMBERSHIP_AGREE_LABEL')"></th>
              <td v-html="$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL')"></td>
            </tr>
          </tbody>
        </table>
        <div class="btn-form">
          <input type="button" class="btn-back" :value="$t('MEMBER_REQUEST_CONFIRM_BACK_BUTTON_LABEL')" @click="parent.editFlag=true"><input type="button" :value="$t('MEMBER_REQUEST_CONFIRM_SEND_BUTTON_LABEL')" @click="registerMemberRequest">
        </div>
      </form>
    </section>
  </div>
</main>
</template>

<script>
import Methods from '@/api/methods';
export default {
  props : {
    parent : {
      type    : Object,
      default : Object
    }
  },
  methods : {
    registerMemberRequest() {
      const params = {
        registerData : this.parent.registerData
      }
      this.$store.state.loading = true
      Methods.apiExcute('request-member', this.$i18n.locale, params).then(data => {
        this.$store.state.loading = false
        this.parent.completedFlag = true
        window.scrollTo({
          top      : 0,
          left     : 0,
          behavior : 'smooth'
        })
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Object.assign(Methods.parseHtmlResponseError(this.$router, error), this.errors)
        })
    }
  }
}
</script>

<style scoped src="@/assets/css/login.css">
</style>
<style scoped src="@/assets/css/set.css">
</style>
<style scoped src="@/assets/css/margin.css">
</style>
<style scoped src="@/assets/css/layout.css">
</style>
<style scoped src="@/assets/css/parts.css">
</style>
<style scoped src="@/assets/css/entry.css">
</style>
<style src="@/assets/css/remodal.css">
</style>
<style scoped src="@/assets/css/remodal-default-theme.css">
</style>
