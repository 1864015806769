<template>
<main id="main">
  <div class="wrap1160">
    <section id="login-form" class="bgLGray">
      <form>
        <table class="tbl-login">
          <tbody>
            <tr>
              <th>
                {{$t("LABEL_LOGINID")}}<em class="req">※</em>
              </th>
              <td>
                <input type="text" v-bind:class="{ 'ime-dis': true, 'err': errors.user_id}" v-model="userId" :placeholder="$t('PLACEHOLDER_USERID')" ref="user_id" required>
                <p class="err-txt">{{errors.user_id}}</p>
              </td>
            </tr>
            <tr>
              <th>
                {{$t("LABEL_PASSWORD")}}<em class="req">※</em>
              </th>
              <td>
                <input
                 ref="password"
                 v-bind:class="{'ime-dis': true, 'err': errors.password}"
                 v-model="password"
                 :placeholder="$t('PLACEHOLDER_PASSWORD')"
                 required
                 :type="isVisiblePassword ? 'text' : 'password'">
                <span @click="() => (isVisiblePassword = !isVisiblePassword)" class="show-password"><Icon :icon="isVisiblePassword ? 'akar-icons:eye-slashed' : 'akar-icons:eye'" style="vertical-align: middle;"/></span>
                <p class="err-txt">{{errors.password}}</p>
              </td>
            </tr>
            <tr>
              <th>
                {{$t("LABEL_USERNAME")}}<em class="req">※</em>
                <span class="note"><img src="@/assets/img/common/ic_form_i.png" alt="注釈"></span>
                <div class="note-modal js-modal">
                  <div class="modal__bg js-modal-close only_sp"></div>
                  <div class="note-txt">
                    <p>
                      {{$t("PS_USERNAME_BEFORE")}}<br>
                      {{$t("PS_USERNAME_AFTER")}}
                    </p>
                  </div>
                </div>
              </th>
              <td>
                <input v-bind:class="{'white-input': true, 'err': errors.user_name}" type="text" v-model="userName" :placeholder="$t('PLACEHOLDER_USERNAME')" ref="user_name" required>
                <p class="err-txt">{{errors.user_name}}</p>
              </td>
            </tr>
            <tr>
              <th class="only_pc">&nbsp;</th>
              <td class="check-idpass">
                <label>
                  <input type="checkbox" v-model="saveLoginInfoFlag" class="checkbox-input">
                  <span class="checkbox-parts space_click_event" tabindex="0">{{$t("LABEL_SAVE_ID_PASSWORD_USERNAME")}}</span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="forget-pass">
          <a @click="$routerGo($define.PAGE_NAMES.REISSUE_PASSWORD_PAGE_NAME)">{{$t("LINK_FORGOT_PASSWORD")}}</a>
        </div>
        <div class="id-pass-err">
          <p class="err-txt">{{errors.message}}</p>
        </div>
        <div class="rule">
          <p class="tit-rule">{{$t("LABEL_PARTICIPATION_TERMS")}}</p>
          <iframe height="100%" width=100% :src="pdfViewerUrl + participationTermsUrl" tabindex="-1"></iframe>
          <div class="rule-check">
            <label for="rule-chk">
              <input type="checkbox" id="rule-chk" class="checkbox-input" required>
              <span class="checkbox-parts space_click_event" tabindex="0">{{$t("LABEL_AGREE_TERMS_PARTICIPATION")}}</span>
            </label>
          </div>
        </div>
        <div class="btn-form">
          <input type="button" id="sbm-login" :value="$t('PAGE_LOGIN')" disabled="disabled" @click="login" tabindex="0">
        </div>
      </form>
      <div class="request">
        <a @click="$routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)">{{$t("LINK_NEW_MEMBER_REGISTRATION_APPLICATION_FREE")}}</a>
        <p>{{$t("PS_MEMBERSHIP_REGISTRATION_REQUIRED")}}</p>
      </div>
    </section>
  </div>
</main>
</template>

<script>
import Methods from '@/api/methods';
import {Icon} from '@iconify/vue2';
import CryptoJS from 'crypto-js';
export default {
  components : {
    Icon
  },
  data() {
    return {
      scripts               : ['login.js'],
      userName              : '',
      pdfViewerUrl          : 'lib/pdfjs-2.10.377-dist/web/viewer.html?file=/',
      participationTermsUrl : '',
      userId                : localStorage[this.$define.LOCAL_STORE_LABEL.STORAGE_USER_ID],
      password              : '',
      errors                : '',
      saveLoginInfoFlag     : false,

      // Show, hide password
      isVisiblePassword : false
    }
  },
  methods : {
    getParticipationTerms() {
      Methods.apiExcute('public/get-participation-terms', this.$i18n.locale).then(data => {
        this.participationTermsUrl = data.file_url
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    checkUserNameFormat() {
      const pattern = new RegExp(this.$t('TEXT_FORMAT'))
      return pattern.test(this.userName)
    },
    login() {
      const loginData = {
        user_id   : this.userId,
        password  : this.password,
        user_name : this.userName
      }
      if (this.userId && this.password && this.userName && !this.checkUserNameFormat()) {
        this.errors = {
          user_name : this.$t('TEXT_FORMAT_ERROR')
        }
        return
      }
      this.$store.state.loading = true
      console.log('loginData: ', loginData)
      Methods.apiExcute('login', this.$i18n.locale, loginData).then(data => {
        console.log('login success')
        this.$cookies.set(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN, data.token, '0', null, null, Methods.boolHttpsProtocol())
        this.$store.state.loading = false
        if (this.saveLoginInfoFlag) {
          localStorage[process.env.VUE_APP_LOCALSTORAGE_LOGIN_INFO_LABEL] = this.aesEncrypt(loginData)
        } else {
          localStorage.removeItem(process.env.VUE_APP_LOCALSTORAGE_LOGIN_INFO_LABEL)
        }
        if (this.$route.query.next) {
          this.$routerGo(this.$route.query.next, this.$route.query.id, {filter : this.$route.query.filter})
        } else {
          this.$routerGo(this.$define.PAGE_NAMES.TOP_PAGE_NAME)
        }
      })
        .catch(error => {
          console.log('login error: ', error)
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
          console.log('errors = ', this.errors)
        })
    },
    aesEncrypt(object) {
      const cipher = CryptoJS.AES.encrypt(JSON.stringify(object), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTOJS_KEY), {
        iv   : CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTOJS_IV),
        mode : CryptoJS.mode.CBC
      })

      return cipher.toString()
    },
    aesDencrypt(txt) {
      const cipher = CryptoJS.AES.decrypt(txt, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTOJS_KEY), {
        iv   : CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTOJS_IV),
        mode : CryptoJS.mode.CBC
      })

      return JSON.parse(CryptoJS.enc.Utf8.stringify(cipher).toString())
    }
  },
  mounted() {
    Methods.addExternalScirpts(this.scripts)
  },
  created() {
    this.getParticipationTerms()
    if (localStorage[process.env.VUE_APP_LOCALSTORAGE_LOGIN_INFO_LABEL]) {
      const loginData = this.aesDencrypt(localStorage[process.env.VUE_APP_LOCALSTORAGE_LOGIN_INFO_LABEL])
      if (loginData?.user_id && loginData.password && loginData.user_name) {
        this.userId = loginData.user_id
        this.password = loginData.password
        this.userName = loginData.user_name
        this.saveLoginInfoFlag = true
      }
    }
  },
  watch : {
    '$i18n.locale'() {
      this.getParticipationTerms()
    },
    errors(value) {
      const keys = Object.keys(value)
      if (keys.length > 0 && this.$refs[keys[0]]) {
        this.$refs[keys[0]].focus()
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .checkbox-parts.focused::before {
    border: 3px solid #000;
  }
  a.focused {
    border-bottom: 2px solid #01a7ac !important;
  }
  #sbm-login.focused {
    border: 3px solid #000;
  }
</style>
<style scoped src="@/assets/css/login.css">
</style>
<style scoped src="@/assets/css/set.css">
</style>
<style scoped src="@/assets/css/margin.css">
</style>
<style scoped src="@/assets/css/layout.css">
</style>
<style scoped src="@/assets/css/parts.css">
</style>
