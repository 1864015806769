import Methods from '@/api/methods'
import Base from '@/common/base'

export default {
  data() {
    return {
      confirmBidDialog   : false,
      bidConfirmItems    : [],
      bidConfirmMessages : []
    }
  },
  methods : {
    openBidConfirmDialog(itemNo, newBidPrice) {
      console.log('openBidConfirmDialog', itemNo)
      this.confirmBidDialog = true
      this.bidConfirmMessages = []

      if (this.parent.nameTag === 'AUCTION_DETAIL_PAGE_NAME' || this.parent.nameTag === 'TENDER_DETAIL_PAGE_NAME') {
        const newPriceItem = Object.assign({}, this.item, {inputBidPrice : this.inputBidPrice})
        this.bidConfirmItems = [].concat(newPriceItem)
        this.bidConfirmItems.map(y => {
          y.bidConfirmSuccess = false
          return y
        })
      } else {
        this.bidConfirmItems = this.items.filter(x => x.exhibition_item_no === itemNo).map(y => {
          y.inputBidPrice = newBidPrice
          y.bidConfirmSuccess = false
          return y
        })
      }
    },
    bidConfirm() {
      const items = this.bidConfirmItems.map(item => {
        return {
          exhibitionItemNo : item.exhibition_item_no,
          bidPrice         : Base.localeString2Number(item.inputBidPrice) * item.bid_status.pitch_width
        }
      })
      this.bidItems(items)
        .then(data => {
          this.bidConfirmMessages = data.bidList.filter(bid => bid.errorMessage)
          // 入札と取消が成功の場合にメッセージを表示する
          if (this.bidConfirmMessages.length === 0) {
            this.bidConfirmItems.map(item => {
              item.bidConfirmSuccess = true
            })
          }
          if (this.bidConfirmMessages.length === 0) {
            this.bidConfirmItems = []
            this.confirmBidDialog = false
          }
        })
        .catch(error => {
          this.$store.state.loading = false
          Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    bidItems(items) {
      const params = {
        bidItems : items
      }
      this.$store.state.loading = true
      return Methods.apiExcute('private/bid-items', this.$i18n.locale, params).then(data => {
        this.$store.state.loading = false
        this.updateViewAfterBid(data.bidList.filter(bid => !bid.errorMessage))
        return Promise.resolve(data)
      })
    },
    closeBidConfirmDialog() {
      this.confirmBidDialog = false
      this.bidConfirmMessages = []
      this.bidConfirmItems = []
    }
  }
}
