<template>
<div class="mypage-header">
  <ul style="padding-left: 0px">
    <li :class="{'active': $route.name == $define.PAGE_NAMES.BID_HISTORY_PAGE_NAME}"><a @click="$routerGo($define.PAGE_NAMES.BID_HISTORY_PAGE_NAME)">{{$t('MY_PAGE_HEADER_BID_HISTORY_TEXT')}}</a></li>
    <li :class="{'active': $route.name == $define.PAGE_NAMES.CHANGE_INFO_PAGE_NAME}"><a @click="$routerGo($define.PAGE_NAMES.CHANGE_INFO_PAGE_NAME)">{{$t('MY_PAGE_HEADER_CHANGE_INFO_TEXT')}}</a></li>
  </ul>
</div>
</template>
<script>
export default {}
</script>
<style scoped lang="scss">
  .mypage-header {
    width: 400px;
    margin-left: auto;
    margin-right: auto;

    ul {
      display: flex;
      flex-direction: row;
    }

    li {
      width: 50%;
      display: inline-block;
      text-align: center;
      height: 50px;
      line-height: 50px;
      font-weight: 700;
      border: 1px solid #d9d9d9;
      a {
        color: #333 !important;
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
      }
      &.active {
        border: 1px solid #000;
        background-color: #000;
        a {
          color: #fff !important;
        }
      }
    }
  }

  @media only screen and (max-width: 430px) {
    .mypage-header {
      width: auto;
    }
    .mypage-header ul {
      width: auto;
      flex-direction: column; 
      padding-left: 24px !important;
      padding-right: 24px;
    }
    .mypage-header li {
      width: 100%;
    }
  }
</style>
