/**
 * 英語
 */
export default {
  SITE_TITLE                              : 'KOBELCO Construction Machinery Official Site│KCM and KCMJ',
  NAME                                    : 'English',
  LANGUAGE_CODE                           : 'EN',
  SIDE_NAME                               : 'Kobelco Construction Machinery Official Site',
  PAGE_TOP                                : 'TOP',
  PAGE_PURCHASE_OF_CONSTRUCTION_EQUIPMENT : 'Product purchase',
  PAGE_AUCTION                            : 'Auction',
  PAGE_AUCTION_EXCAVATOR                  : 'Excavator & Others',
  PAGE_AUCTION_CRANE                      : 'Crane',
  PAGE_TENDER                             : 'Tender',
  PAGE_TENDER_EXCAVATOR                   : 'Excavator & Others',
  PAGE_TENDER_CRANE                       : 'Crane',
  PAGE_INVENTORY_INFORMATION              : 'Stock List',
  PAGE_INVENTORY_INFORMATION_EXCAVATOR    : 'Excavator & Others',
  PAGE_INVENTORY_INFORMATION_CRANE        : 'Crane',

  HEADER_BIDDING_BUTTON_LABEL             : 'Bidding',
  HEADER_FAVORITE_LIST_BUTTON_LABEL       : 'Wish List',
  HEADER_LOGOUT_BUTTON_LABEL              : 'Sign out',
  PAGE_MEMBER_REGISTRATION                : 'Members',
  PAGE_MEMBER_REGISTRATION_NAME           : 'Member registration application',
  PAGE_LOGIN                              : 'Sign in',
  PAGE_NEW_MEMBER_REGISTRATION            : 'New member registration',
  PAGE_MY_PAGE                            : 'MyPage',
  PAGE_BEGINNER_GUIDE                     : 'Guides',
  PAGE_GUIDE_WEB_AUCTION_FLOW             : 'Flow of WEB auction',
  PAGE_GUIDE_WEB_AUCTION_TAB1             : 'Web Auction',
  PAGE_GUIDE_WEB_AUCTION_TAB2             : 'Web Tender',
  PAGE_GUIDE_WEB_AUCTION_TAB3             : 'Stock discussion',
  PAGE_GUIDE_BID_MEETING_FLOW             : 'Flow of bidding',
  LINK_VENUE_ACCESS                       : 'Access Map',
  LINK_INTRODUCTION_SECONDHAND_CAR_CENTER : 'Introduction of Used Machinery Center',
  LINK_ICHIKAWA_SECONDHAND_CAR_CENTER     : 'Ichikawa Used Machinery Center',
  LINK_KOBE_SECONDHAND_CAR_CENTER         : 'Kobe Used Machinery Center',
  LINK_YOKOHAMA_SECONDHAND_CAR_CENTER     : 'Yokohama Used Machinery Center',
  PAGE_GUIDANCE                           : 'Help',
  LINK_GUIDANCE_FAQ                       : 'FAQ',
  PAGE_GUIDANCE_CONTACT                   : 'Contact us',
  LINK_IN_HOUSE_CATALOG                   : 'Catalog',
  LINK_FOR_JAPANESE_PC                    : 'For users in Japan<br>click here',
  LINK_FOR_JAPANESE_SP                    : 'Click here for users in Japan',
  LINK_KOBELCO_KENKI_GUILD                : 'About us',
  LINK_KOBELCO_KENKI_PROFILE              : 'About us',
  LINK_KOBELCO_KENKI_PRIVACY              : 'Privacy Policy',
  LINK_ANNOUNCEMENT                       : 'Announcement',
  LINK_ANNOUNCEMENT_DENSI                 : 'E-announcement',
  LINK_ANNOUNCEMENT_KESSAI                : 'Financial Results',
  LINK_ANNOUNCEMENT_NOT_FOUND             : 'There is no announcement.',
  LINK_ANNOUNCEMENT_PDF_NOTICE            : 'To view PDF file, you need to have Adobe Acrobat Reader software installed on your computer.<br>Click on the left banner to download.',
  LINK_CONFIRM_MEMBERSHIP                 : 'Membership terms & conditions',
  FOOTER_COPYRIGHT                        : '<small>Copyright© 2021 <br class="only_sp">Kobelco Construction Machinery Co.,Ltd. All Rights Reserved.</small>',
  FOOTER_KOBUTSU                          : '<span>Secondhand dealer license number (Kobelco Construction Machinery Co.,Ltd.) </span><span>301040005377(Tokyo Public Safety Commission licensed)</span><br /><span>Secondhand dealer license number (Kobelco Construction Machinery Japan Co.,Ltd.) </span><span>441100001519(Chiba Prefecture Public Safety Commission licensed)</span>',

  LABEL_LOGINID                                 : 'User ID',
  PLACEHOLDER_USERID                            : '',
  LABEL_PASSWORD                                : 'Password',
  PLACEHOLDER_PASSWORD                          : '',
  LABEL_USERNAME                                : 'User Name',
  PLACEHOLDER_USERNAME                          : '',
  PS_USERNAME_BEFORE                            : 'Please enter the name of the person in charge of logging in.',
  PS_USERNAME_AFTER                             : 'Can check the history by person in charge, after logging in.',
  LABEL_SAVE_ID_PASSWORD_USERNAME               : 'Save User ID / Password / User Name',
  LINK_FORGOT_PASSWORD                          : 'Forgot password?',
  LINK_FORGOT_PASSWORD_NAME                     : 'Forgot password',
  LABEL_PARTICIPATION_TERMS                     : 'Terms of Service',
  LABEL_AGREE_TERMS_PARTICIPATION               : 'I agree to the Terms of Service',
  LINK_NEW_MEMBER_REGISTRATION_APPLICATION_FREE : 'New member registration(free)',
  PS_MEMBERSHIP_REGISTRATION_REQUIRED           : '*Member account is required to see the price of construction equipment.',
  OTHER_LANGUAGE                                : '日本語',
  OTHER_LANGUAGE_SHOT                           : 'JA',
  OTHER_LANGUAGE_CODE                           : 'ja',
  DATE_FORMAT                                   : 'dd, DD/MM/YYYY',
  DATE_TIME_FORMAT                              : 'dd, DD/MM/YYYY HH:mm',
  ROW_BID_DATE_TIME_FORMAT                      : 'DD/MM HH:mm',

  TEXT_FORMAT       : '^([^\x01-\x7E]|[0-9a-zA-Z -/:-@\[-~])+$',
  TEXT_FORMAT_ERROR : 'Please enter in half-width alphanumerical characters, full-width characters, and symbols.',

  CURRENT_PASSWORD_ERROR_MESSAGE : 'When changing the password, enter the current password.',
  PASSWORD_CONFIRM_ERROR_MESSAGE : 'The password confirmation does not match.',
  MAIL_CONFIRM_ERROR_MESSAGE     : 'The email confirmation does not match.',

  NO_RECOMMEND_ITEM_IN_SESSION_MESSAGE  : 'Current {names} bid opening ',
  NO_RECOMMEND_ITEM_SCHEDULED_MESSAGE   : 'the next upcoming auction will take place <br> {start_day}~<br>Please look forward to it.',
  NO_RECOMMEND_ITEM_NO_SCHEDULE_MESSAGE : 'Please look forward to the next upcoming auction.',

  TOP_PAGE_AUCTION_RECOMMEND_ITEMS_VIEW_HEADER            : 'WEB auction / Tender machine',
  TOP_PAGE_AUCTION_NEW_ITEMS_VIEW_HEADER                  : 'Stock machine new arrival',
  TOP_PAGE_LIST_OF_AUCTION_EXHIBITORS_BUTTON_PREFIX_LABEL : 'Auction',
  TOP_PAGE_LIST_OF_AUCTION_EXHIBITORS_BUTTON_SUBFIX_LABEL : 'Machine List',
  TOP_PAGE_LIST_OF_TENDER_EXHIBITORS_BUTTON_PREFIX_LABEL  : 'Tender',
  TOP_PAGE_LIST_OF_TENDER_EXHIBITORS_BUTTON_SUBFIX_LABEL  : 'Machine List',
  TOP_PAGE_CRANE_LIST_BUTTON_PREFIX_LABEL                 : 'Crane',
  TOP_PAGE_CRANE_LIST_BUTTON_SUBFIX_LABEL                 : 'List',
  TOP_PAGE_AUCTION_NEW_ITEMS_BUTTON_LABEL                 : 'List',
  TOP_PAGE_INVENTORY_MACHINE_LIST_BUTTON_LABEL            : 'Stock Machine List',
  TOP_PAGE_LIST_OF_CRANE_STOCK_MACHINES_BUTTON_LABEL      : 'Cranes Stock List',
  TOP_PAGE_AUCTION_SCHEDULE_VIEW_HEADER                   : 'Bid Schedule',
  TOP_PAGE_AUCTION_STATUS_AUCTION_IN_SESSION_LABEL        : 'Opening',
  TOP_PAGE_AUCTION_STATUS_AUCTION_WILL_BE_HELD_LABEL      : 'Upcoming',
  TOP_PAGE_AUCTION_STATUS_AUCTION_ENDED_LABEL             : 'Ended',
  TOP_PAGE_ITEM_MODEL_LABEL                               : 'Model',
  TOP_PAGE_ITEM_MODEL_SERIAL_NUMBER_LABEL                 : 'Unit',
  TOP_PAGE_ITEM_MODEL_YEAR_LABEL                          : 'Years',
  TOP_PAGE_NOTICES_VIEW_HEADER                            : 'Notice',
  TOP_PAGE_FOR_NEWBIE_LABEL                               : 'Guides',
  TOP_PAGE_AUCTION_GUIDE_LINK_LABEL                       : 'Flow of WEB auction / Bidding',
  TOP_PAGE_PRICE_CURRENCY_MARK                            : 'JPY ',

  MEMBER_REQUEST_DOCUMENT_EXPLANATION_MESSAGE           : 'We are reviewing the application for membership registration.</br>Please download from the following and prepare the necessary documents</br>in PDF (JPEG / PNG is also possible).',
  MEMBER_REQUEST_DOCUMENT_CAUTION_MESSAGE               : "* When registering as a member in the name of an individual, description in the notes of individual name instead of a company name for 2, and please submit a copy of your personal identification card such as a driver's license for 3.",
  MEMBER_REQUEST_DOCUMENT_TITLE                         : 'Documents required for membership application',
  MEMBER_REQUEST_DOWNLOAD_SAMPLE_DOCUMENT_BUTTON_LABEL  : 'Download application required documents',
  MEMBER_REQUEST_FORM_CAUTION_TITLE                     : '[Caution] To construction machinery users in Japan',
  MEMBER_REQUEST_FORM_CAUTION_MESSAGE                   : '<span class = "fontB">[Caution] To construction machinery users in Japan</span><br> Kobelco Construction Machinery Co., Ltd, Kobelco Construction Machinery Japan Co., Ltd does not sell directly construction machinery to users in Japan.<br> Anyone who wishes to purchase, please contact from <a href="https://www.kobelco-kenki.co.jp/" target="_blank">Sales Service (Go to the Kobelco Construction Machinery website ). </a>',
  MEMBER_REQUEST_FORM_ENTRY_FORM_INFO_MESSAGE           : 'Once you have the required documents, please fill out the form below. Please be sure to enter the items marked with<br><em class = "req"> required</em>.',
  MEMBER_REQUEST_FORM_COUNTRY_LABEL                     : 'Country<em class = "req">required</em>',
  MEMBER_REQUEST_FORM_COMPANY_NAME_LABEL                : 'Company name / Organization name<em class = "req">required</ em>',
  MEMBER_REQUEST_FORM_REPRESENTATIVE_DIRECTOR_LABEL     : 'Representative name',
  MEMBER_REQUEST_FORM_BRANCH_OFFICE_LABEL               : 'Branch office ・ sales office name',
  MEMBER_REQUEST_FORM_USER_NAME_LABEL                   : 'Name of person in charge<em class = "req"> required</em>',
  MEMBER_REQUEST_FORM_POST_CODE_LABEL                   : 'Zip code',
  MEMBER_REQUEST_FORM_ADDRESS_LABEL                     : 'Address<em class = "req">required</em>',
  MEMBER_REQUEST_FORM_REQUIRED_LABEL                    : 'required',
  MEMBER_REQUEST_FORM_PREFECTURES_LABEL                 : 'Prefectures',
  MEMBER_REQUEST_FORM_MUNICIPALITIES_LABEL              : 'Municipalities',
  MEMBER_REQUEST_FORM_LOCATION_LABEL                    : 'Location',
  MEMBER_REQUEST_FORM_LOCATION_LABEL_EN                 : 'Address',
  MEMBER_REQUEST_FORM_TEL_LABEL                         : 'Phone number<em class = "req"> required </ em>',
  MEMBER_REQUEST_FORM_FAX_LABEL                         : 'Fax number',
  MEMBER_REQUEST_FORM_EMAIL_LABEL                       : 'Email<em class = "req"> required </ em>',
  MEMBER_REQUEST_FORM_CONFIRM_EMAIL_LABEL               : 'Email<br class="only_pc">(Confirmation)<em class = "req">required</em>',
  MEMBER_REQUEST_FORM_PASSWORD_LABEL                    : 'Password<em class = "req">required</em>',
  MEMBER_REQUEST_FORM_CONFIRM_PASSWORD_LABEL            : 'Password(Confirmation) <em class = "req"> required</em>',
  MEMBER_REQUEST_FORM_PASSWORD_RULE                     : '<p class="kome">*Your password must be between 8 and 14 characters and contain at least 1 number.</p>',
  MEMBER_REQUEST_FORM_ANTIQUE_FILE_LABEL                : 'A copy of<br class="only_pc">secondhand dealer permit<em class = "req">required</em>',
  MEMBER_REQUEST_FORM_EXPORT_FILE_LABEL                 : 'Related to<br class="only_pc">Signed Export Control<br class="only_sp">Notes<em class = "req">required</em>',
  MEMBER_REQUEST_FORM_NAME_CARD_FILE_LABEL              : 'Business card copy<br class="only_pc">of the Representative<em class = "req">required</ em>',
  MEMBER_REQUEST_FORM_AGREE_LABEL                       : 'Handling of<br class="only_pc">personal information<em class = "req">required</em>',
  MEMBER_REQUEST_FORM_MEMBERSHIP_AGREE_LABEL            : 'Regarding <br class="only_pc">membership terms and conditions<em class="req">required</em>',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL              : 'I Agree',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE1   : 'Please be sure to read',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE2   : 'Privacy Policy',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE2_1 : 'Membership Terms & Conditions',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE3   : 'before submitting.',
  MEMBER_REQUEST_FORM_FILE_SELECT_BUTTON_LABEL          : 'Select files',
  MEMBER_REQUEST_FORM_FILE_DELETE_BUTTON_LABEL          : 'Delete',
  MEMBER_REQUEST_FORM_FILE_UNSELECTED_MESSAGE           : 'File is not selected.',
  MEMBER_REQUEST_FORM_FILE_UPLOAD_CAUTION_MESSAGE       : '<p class="kome">*PDF / JPEG / PNG:5MB or less</p><p class="kome">*Please set the file name in half-width alphanumerical characters before attaching.</p>',
  MEMBER_REQUEST_FORM_FILE_UPLOAD_CAUTION_NONJP         : '<p class="kome">*Please upload English version.</p>',
  MEMBER_REQUEST_FORM_FILE_UPLOAD_CAUTION_HEADER        : 'Documents required for membership application',
  MEMBER_REQUEST_FORM_SUBMIT_LABEL                      : 'Confirm',
  MEMBER_REQUEST_FORM_SEARCH_POST_CODE_BUTTON_LABEL     : 'Search',
  MEMBER_REQUEST_FORM_FULL_WIDTH_LABEL                  : '[Full-width]',
  MEMBER_REQUEST_FORM_FULL_WIDTH_NUMBERS_LABEL          : '[Half-width numbers]',
  MEMBER_REQUEST_FORM_FULL_WIDTH_ALPHANUMERICAL_LABEL   : '[Half-width alphanumerical]',
  MEMBER_REQUEST_FORM_POST_CODE_PLACEHOLDER             : '',
  MEMBER_REQUEST_FORM_ADDRESS_PLACEHOLDER               : '',
  MEMBER_REQUEST_FORM_PREFECTURES_PLACEHOLDER           : '',
  MEMBER_REQUEST_FORM_MUNICIPALITIES_PLACEHOLDER        : '',
  MEMBER_REQUEST_FORM_LOCATION_PLACEHOLDER              : '',
  MEMBER_REQUEST_FORM_PHONE_NUMBER_PLACEHOLDER          : '',
  MEMBER_REQUEST_FORM_FAX_NUMBER_PLACEHOLDER            : '',
  MEMBER_REQUEST_FORM_WITHDRAW_LABEL                    : 'Withdraw',
  MEMBER_REQUEST_TEXT_FORMAT_ERROR                      : 'Please enter in half-width alphanumericals / symbols.',
  MEMBER_EDIT_FORM_FILE_CHANGING_LABEL                  : '*Please contact us for changing attachment file',
  MEMBER_EDIT_FORM_EXPORT_FILE_LABEL                    : 'A copy of<br class="only_pc">secondhand dealer permit',
  MEMBER_EDIT_FORM_ANTIQUE_FILE_LABEL                   : 'Related to<br class="only_pc">Signed Export Control<br class="only_sp">Notes',
  MEMBER_EDIT_FORM_NAME_CARD_FILE_LABEL                 : 'Business card copy<br class="only_pc">of the Representative',

  MEMBER_REQUEST_CONFIRM_EXPLANATION_MESSAGE           : 'Please confirm and if there are no mistakes, click 「Send」.',
  MEMBER_REQUEST_CONFIRM_COUNTRY_LABEL                 : 'Country',
  MEMBER_REQUEST_CONFIRM_COMPANY_NAME_LABEL            : 'Company name ・ Organization name',
  MEMBER_REQUEST_CONFIRM_REPRESENTATIVE_DIRECTOR_LABEL : 'Representative name',
  MEMBER_REQUEST_CONFIRM_BRANCH_OFFICE_LABEL           : 'Branch office ・ sales office name',
  MEMBER_REQUEST_CONFIRM_USER_NAME_LABEL               : 'Name of person in charge',
  MEMBER_REQUEST_CONFIRM_POST_CODE_LABEL               : 'Zip code',
  MEMBER_REQUEST_CONFIRM_ADDRESS_LABEL                 : 'Address',
  MEMBER_REQUEST_CONFIRM_PREFECTURES_LABEL             : 'Prefectures',
  MEMBER_REQUEST_CONFIRM_MUNICIPALITIES_LABEL          : 'Municipalities',
  MEMBER_REQUEST_CONFIRM_LOCATION_LABEL                : 'Location',
  MEMBER_REQUEST_CONFIRM_TEL_LABEL                     : 'Phone number',
  MEMBER_REQUEST_CONFIRM_FAX_LABEL                     : 'Fax number',
  MEMBER_REQUEST_CONFIRM_EMAIL_LABEL                   : 'Email',
  MEMBER_REQUEST_CONFIRM_CONFIRM_EMAIL_LABEL           : 'Email<br class="only_pc">(Confirmation)',
  MEMBER_REQUEST_CONFIRM_PASSWORD_LABEL                : 'Password',
  MEMBER_REQUEST_CONFIRM_CONFIRM_PASSWORD_LABEL        : 'Password(Confirmation)',
  MEMBER_REQUEST_CURRENT_PASSWORD_LABEL                : 'Password(Current)',
  MEMBER_REQUEST_CONFIRM_EXPORT_FILE_LABEL             : 'A copy of<br class="only_pc">secondhand dealer permit',
  MEMBER_REQUEST_CONFIRM_ANTIQUE_FILE_LABEL            : 'Related to<br class="only_pc">Signed Export Control<br class="only_sp">Notes',
  MEMBER_REQUEST_CONFIRM_NAME_CARD_FILE_LABEL          : 'Business card copy<br class="only_pc">of the person in charge',
  MEMBER_REQUEST_CONFIRM_AGREE_LABEL                   : 'Handling of<br class="only_pc">personal information',
  MEMBER_REQUEST_CONFIRM_MEMBERSHIP_AGREE_LABEL        : 'Regarding <br class="only_pc">membership terms and conditions',
  MEMBER_REQUEST_CONFIRM_BACK_BUTTON_LABEL             : 'Back',
  MEMBER_REQUEST_CONFIRM_SEND_BUTTON_LABEL             : 'Send',

  MEMBER_REQUEST_COMPLETION_MESSAGE                  : 'The membership application has been completed.',
  MEMBER_REQUEST_COMPLETION_GO_TO_LOGIN_BUTTON_LABEL : 'Back to Sign in page',

  REISSUE_PASSWORD_EXPLANATION_MESSAGE                 : 'If you have forgotten your password, please enter your registered ID and email. <br>When you press the [Send] button, the password will be sent to the registered email.',
  REISSUE_PASSWORD_CAUTION_MESSAGE                     : 'If you have forgotten your ID, please contact us.',
  REISSUE_PASSWORD_FORM_ID_LABEL                       : 'ID',
  REISSUE_PASSWORD_FORM_EMAIL_LABEL                    : 'Email',
  REISSUE_PASSWORD_FORM_CONFIRM_EMAIL_LABEL            : 'Email(Confirmation)',
  REISSUE_PASSWORD_FORM_ID_PLACEHOLDER                 : 'Half-width alphanumerical',
  REISSUE_PASSWORD_FORM_EMAIL_PLACEHOLDER              : 'Half-width alphanumerical',
  REISSUE_PASSWORD_FORM_SUBMIT_LABEL                   : 'Send',
  REISSUE_PASSWORD_COMPLETION_MESSAGE                  : 'We have sent a new password to your email.',
  REISSUE_PASSWORD_COMPLETION_GO_TO_LOGIN_BUTTON_LABEL : 'Back to Sign in page',

  AUCTION_PAGE_SEARCH_HEADER : 'Auction machine search conditions',
  AUCTION_PAGE_HEADER_LABEL  : 'Auction machine',
  TENDER_PAGE_SEARCH_HEADER  : 'Tender machine search conditions',
  TENDER_PAGE_HEADER_LABEL   : 'Tender machine',
  STOCK_PAGE_SEARCH_HEADER   : 'Stock machine search conditions',
  STOCK_PAGE_HEADER_LABEL    : 'Stock machine',

  AUCTION_PAGE_SEARCH_TYPE : 'Auction',
  TENDER_PAGE_SEARCH_TYPE  : 'Tender',
  STOCK_PAGE_SEARCH_TYPE   : 'Stock machine',

  ITEM_SEARCH_KEYWORD_LABEL                          : 'Keyword',
  ITEM_SEARCH_KEYWORD_PLACEHOLDER                    : '例)SK200',
  ITEM_SEARCH_INVENTORY_AREA_LABEL                   : 'Stock place',
  ITEM_SEARCH_CATEGORY_LABEL                         : 'Category',
  ITEM_SEARCH_MODEL_YEAR_LABEL                       : 'Years',
  ITEM_SEARCH_PRICE_LABEL                            : 'Price',
  ITEM_SEARCH_PRICE_UNIT                             : '10K',
  ITEM_SEARCH_FAVORITE_LABEL                         : 'Favorite',
  ITEM_SEARCH_BIDDING_LABEL                          : 'Bidding',
  ITEM_SEARCH_EXCEEDING_THE_LOWEST_BID_LABEL         : 'Exceeding the lowest bid',
  ITEM_SEARCH_SOLD_OUT_LABEL                         : 'Other than sold out',
  ITEM_SEARCH_RECOMMEND_LABEL                        : 'Recommend',
  ITEM_SEARCH_SEARCH_BUTTON_LABEL                    : 'Search',
  ITEM_SEARCH_CONDITIONS_LABEL                       : 'Conditions',
  ITEM_SEARCH_SEARCH_RESULTS_LABEL                   : 'Search results',
  ITEM_SEARCH_SEARCH_RESULTS_SUBJECT_LABEL           : '',
  ITEM_SEARCH_SEARCH_RESULTS_NUMBER_SUBJECT_LABEL    : ' Units',
  ITEM_SEARCH_CATEGORY_SEARCH_RESULTS_NUMBER_LABEL   : ' Units',
  ITEM_SEARCH_PRINT_ITEMS_CSV_BUTTON_LABEL           : 'Machine list CSV',
  ITEM_SEARCH_BULK_BIDDING_BUTTON_LABEL              : 'Place bids all at once',
  ITEM_SEARCH_CURRENT_PRICE_SORT_LABEL               : 'Current price',
  ITEM_SEARCH_RESULTS_LIMIT_LABEL                    : 'Results per page',
  ITEM_SEARCH_BULK_BIDDING_MODAL_HEADER              : 'Place bids all at once',
  ITEM_SEARCH_CONFIRM_BIDDING_MODAL_HEADER           : 'Bid confirmation',
  ITEM_SEARCH_BULK_BIDDING_MODAL_BID_BUTTON_LABEL    : 'Place a bid',
  ITEM_SEARCH_BULK_BIDDING_MODAL_CANCEL_BUTTON_LABEL : 'Close',

  ITEM_ROW_TOP_STATUS_LABEL                       : 'Highest bidder',
  ITEM_ROW_SECOND_STATUS_LABEL                    : 'Second Bidder',
  ITEM_ROW_EXCEEDING_THE_LOWEST_BID_STATUS_LABEL  : 'Exceeding the lowest bid',
  ITEM_ROW_MORE_LITTLE_LABEL                      : 'A little after',
  ITEM_ROW_CANCEL_LABEL                           : 'Stop listing',
  ITEM_ROW_AUCTION_STATUS_LABEL                   : 'Auction',
  ITEM_ROW_MAKER_LABEL                            : 'Maker',
  ITEM_ROW_YYYY_LABEL                             : 'Years',
  ITEM_ROW_YYYY_UNIT_LABEL                        : '',
  ITEM_ROW_YYYY_NOT_CLEAR_LABEL                   : '',
  ITEM_ROW_MDLGR_LABEL                            : 'Model',
  ITEM_ROW_OPETIM_LABEL                           : 'Hours',
  ITEM_ROW_OPETIM_NOT_CLEAR_LABEL                 : '',
  ITEM_ROW_ACTSERNO_LABEL                         : 'Unit',
  ITEM_ROW_CURRENT_PRICE_LABEL                    : 'Current price',
  ITEM_ROW_START_PRICE_LABEL                      : 'Lowest bid price',
  ITEM_ROW_PRICE_CURRENCY_MARK                    : 'JPY ',
  ITEM_ROW_START_STATUS_LABEL                     : 'Start',
  ITEM_ROW_END_STATUS_LABEL                       : 'End',
  ITEM_ROW_ENDED_STATUS_LABEL                     : 'Ended',
  ITEM_ROW_SELLING_PRICE_LABEL                    : 'Selling price',
  ITEM_ROW_SOLD_OUT_LABEL                         : 'SOLD OUT',
  ITEM_ROW_BID_PRICE_LABEL                        : 'Bid price',
  ITEM_ROW_BID_BUTTON_LABEL                       : 'Place a bid',
  ITEM_ROW_MEMBERS_ONLY_LABEL                     : 'Members only',
  ITEM_ROW_LOGIN_LINK_LABEL                       : 'Sign in',
  ITEM_ROW_NEW_MEMBER_REGISTRATION_BUTTON_LABEL   : 'New member registration(free)',
  ITEM_ROW_DETAIL_LINK_LABEL                      : 'Details',
  ITEM_ROW_BULK_BIDDING_MODAL_DELETE_BUTTON_LABEL : 'Delete',
  ITEM_ROW_BID_SUCCESS_MESSAGE                    : 'Your bid has been accepted.',
  ITEM_ROW_CANCEL_SUCCESS_MESSAGE                 : 'Your bid has been cancelled.',
  ITEM_ROW_BID_CONFIRM_MESSAGE                    : 'Make sure about your bidding price, and click the Place a bid Button.',
  ITEM_ROW_MULTIPLE_ITEMS_LABEL                   : ' units',

  AUCTION_DETAIL_PAGE_NAME : 'Auction detail',
  STOCK_DETAIL_PAGE_NAME   : 'Stock machine detail',
  TENDER_DETAIL_PAGE_NAME  : 'Tender detail',

  DETAIL_LOWEST_BID_ACCEPT_PRICE_LABEL           : 'Lowest bid price',
  DETAIL_BID_STATUS_LABEL                        : 'Bid status',
  DETAIL_BID_STATUS_UNBID_LABEL                  : 'Unbidden',
  DETAIL_BID_STATUS_BIDED_LABEL                  : 'Completed bid',
  DETAIL_BID_STATUS_MORE_LITTLE_LABEL            : 'A little after',
  DETAIL_BID_STATUS_SUCCESSFUL_BID_RIGHT_LABEL   : 'Has a winning bid right',
  DETAIL_BID_AUTOMATIC_BIDDING_LABEL             : 'Automatic bidding',
  DETAIL_DOWNLOAD_IMAGES_BUTTON_LABEL            : 'Download all images',
  DETAIL_CONTACT_PRODUCT_BUTTON_LABEL            : 'Contact us about this product',
  DETAIL_DOWNLOAD_INSPECTION_REPORT_BUTTON_LABEL : 'See the Inspection certificate',
  DETAIL_PRODUCT_INFORMATION_LABEL               : 'Product information',
  DETAIL_AWAMETER_LABEL                          : 'Hour meter',
  DETAIL_MILEAGE_LABEL                           : 'Mileage',
  DETAIL_STOCK_LOCATION_LABEL                    : 'Stock place',
  DETAIL_VEHICLE_INSPECTION_LABEL                : 'Vehicle inspection',
  DETAIL_MAXIMUM_RATED_TOTAL_LOAD_LABEL          : 'Maximum rated total load',
  DETAIL_CR_INSPECTION_LABEL                     : 'CR inspection',
  DETAIL_SPECIFICATION_LABEL                     : 'Specification',
  DETAIL_HOOK_LABEL                              : 'Hook',
  DETAIL_OUTRIGGER_LABEL                         : 'Outrigger',
  DETAIL_REMARKS_LABEL                           : 'Remarks',
  DETAIL_CONTACT_PRODUCT_BOTTOM_BUTTON_LABEL     : 'Contact us about product',
  DETAIL_BACK_TO_ITEM_SEARCH_BUTTON_LABEL        : 'Back to search results',
  DETAIL_NOTE_TEXT_TOP                           : 'As-is storage, as-is delivery, pre-sale priority, no guarantee',
  DETAIL_NOTE_TEXT_BOTTOM                        : 'Important: Please be careful about the storage area.',
  DETAIL_LIST_OF_STORAGE_LOCATIONS_BUTTON_LABEL  : 'List of storage area',
  DETAIL_ITEM_LOT_ITEMS_PS_TEXT                  : '* This lot contains multiple products.',
  DETAIL_ITEM_PREV_BUTTON_LABEL                  : 'prev',
  DETAIL_ITEM_NEXT_BUTTON_LABEL                  : 'next',
  DETAIL_ITEM_SNS_SHARE_LABEL                    : 'Share this item',
  DETAIL_ITEM_HOLDING_PERIOD_LABEL               : 'Opening period',
  DETAIL_ITEM_SNS_SHARE_TEXT                     : '',
  DETAIL_ITEM_BID_REMAINING_TIME                 : 'Time remaining',
  DETAIL_ITEM_BID_EXTENDED_END                   : 'Entended at',

  CONTACT_PAGE_NAME                  : 'Inquiry',
  ITEM_CONTACT_PAGE_NAME             : 'Inquiries about product',
  CONTACT_FORM_INFO_TEXT             : 'Please input the following items, be sure to input <em class = "req"> required</em> item.',
  CONTACT_FORM_CONTACT_CONTENT_LABEL : 'Content of Inquiry',
  CONTACT_EXPORT_FILE_LABEL          : 'Attachment',
  CONTACT_FORM_GUEST_TEXT            : 'The guests',
  CONTACT_FORM_UDRCARR_LABEL         : 'Undercarriage',
  CONTACT_FORM_CAB_LABEL             : "Driver's cab",
  CONTACT_FORM_CLASS_LABEL           : 'Class',
  CONTACT_FORM_AREA_LABEL            : 'Storage area',
  CONTACT_COMPLETION_MESSAGE         : 'The inquiry has been completed.',

  MY_PAGE_SUCCESSFUL_BID_HISTORY_SEARCH_CONDITION_VIEW_HEADER : 'Winning bids search condition',
  MY_PAGE_SEARCH_CONDITION_METHOD_LABEL                       : 'Method',
  MY_PAGE_SEARCH_CONDITION_BIDDING_DATE_LABEL                 : 'Ending date',
  MY_PAGE_SEARCH_CONDITION_BIDDING_FROM_DATE_PLACEHOLDER      : 'ex) 2021/01/01',
  MY_PAGE_SEARCH_CONDITION_BIDDING_TO_DATE_PLACEHOLDER        : 'ex) 2021/01/01',
  MY_PAGE_SEARCH_BUTTON_LABEL                                 : 'Search',
  MY_PAGE_SEARCH_CONDITION_LABEL                              : 'Conditions',
  MY_PAGE_SEARCH_RESULT_LABEL                                 : 'Search results',
  MY_PAGE_SEARCH_RESULT_COUNT_LABEL                           : '{count} results',
  MY_PAGE_SEARCH_RESULT_DISPLAY_COUNT_LABEL                   : 'Results per page',
  MY_PAGE_SEARCH_RESULT_SORT_DISPLAY_COUNT_LABEL              : '{display_count}',
  MY_PAGE_ITEM_BIDDING_END_DATETIME_LABEL                     : 'Ending datetime',
  MY_PAGE_COLON_LABEL                                         : ':',
  MY_PAGE_ITEM_MAKER_LABEL                                    : 'Maker',
  MY_PAGE_ITEM_MODEL_LABEL                                    : 'Model',
  MY_PAGE_ITEM_YEAR_LABEL                                     : 'Years',
  MY_PAGE_ITEM_OPERATING_TIME_LABEL                           : 'Awameter',
  MY_PAGE_ITEM_MODEL_SERIAL_NUMBER_LABEL                      : 'Unit',
  MY_PAGE_ITEM_CONTRACT_PRICE_LABEL                           : 'Contract price',
  MY_PAGE_ITEM_BID_PRICE_LABEL                                : 'Bid price',
  MY_PAGE_ITEM_CONTRACT_BIDDER_LABEL                          : 'Winning Bidder',
  MY_PAGE_ITEM_PRODUCT_IMAGE_DOWNLOAD_BUTTON_LABEL            : 'Product image download',
  MY_PAGE_SHOW_MORE_BUTTON_LABEL                              : 'See more',
  MY_PAGE_PRICE_CURRENCY_MARK                                 : 'JPY ',
  MY_PAGE_YEAR_MARK                                           : '',
  MY_PAGE_NO_MARK                                             : 'No.',
  MY_PAGE_SHOW_ITEMS_LINK_LABEL                               : 'Show lot details',
  MY_PAGE_HIDE_ITEMS_LINK_LABEL                               : 'Close lot details',
  MY_PAGE_HEADER_BID_HISTORY_TEXT                             : 'Successful bid history',
  MY_PAGE_HEADER_CHANGE_INFO_TEXT                             : 'Edit member information',

  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_HEADER_TEXT      : 'Confirmation dialog',
  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_MESSAGE_TEXT     : 'May I withdraw from the membership?',
  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_OK_LABEL         : 'OK',
  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_CANCLE_LABEL     : 'Close',

  OTHERS_ORDER_RULE_TEXT                                      : 'Terms and Conditions',
  OTHERS_ORDER_INTRO_RULE_TEXT                                : 'If you wish to purchase our products, you must agree to the following terms and conditions before doing business with us.',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_TEXT                         : 'Security Trade Control',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_DESCRIPTION_TEXT             : 'We implement security trade control from the perspective of preventing the proliferation of weapons of mass destruction and other weapons, preventing excessive accumulation of conventional weapons, and maintaining international peace and security.',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_1_TEXT                   : 'Export Control Policy',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_1_DESCRIPTION_1_TEXT     : 'We will comply with the Foreign Exchange and Foreign Trade Act and other export-related laws and regulations (hereinafter referred to as the "Foreign Exchange Law, etc."), as well as laws and regulations regarding the re-export of U.S. products, and will not export goods or provide technology in violation of these laws and regulations.',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_1_DESCRIPTION_2_TEXT     : 'We will clearly stipulate and ensure the implementation of procedures for determining whether or not a transaction is relevant, customer screening, and transaction screening based on a comprehensive consideration of these factors.',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_1_DESCRIPTION_3_TEXT     : 'When conducting transactions, we will confirm the intended use of the cargo and technology, as well as the end customer, in an effort to prevent the proliferation of weapons of mass destruction and conventional weapons-related items such as weapons.',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_2_TEXT                   : 'Confirmation of transaction details',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_2_DESCRIPTION_1_TEXT     : 'In accordance with the policy in A) above, we may confirm the details of transactions with customers and conduct transaction screening.',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_2_DESCRIPTION_2_TEXT     : 'Depending on the results of the screening, we may impose conditions specified by us or refrain from conducting the transaction itself.',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_2_DESCRIPTION_3_TEXT     : 'Please note that the delivery date may be delayed depending on the screening process.',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_3_TEXT                   : 'Sign the "Notes on Export Control"',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_3_DESCRIPTION_1_TEXT     : 'In addition to the implementation of B) above, it is a condition of the transaction that you sign the "Notes on Export Control" issued by us.If necessary, we will mail you the form.',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_3_DESCRIPTION_2_TEXT     : 'In addition, you will be required to sign a renewal of the document periodically, taking into account revisions to the Foreign Exchange and Foreign Trade Control Law, your business performance with us, and other factors.',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_3_DESCRIPTION_3_TEXT     : "If you are starting a new business, you will need to submit a copy of your company's antique dealer permit to us.",
  OTHERS_ORDER_INTRO_RULE_MAIN_2_TEXT                         : 'Compliance',
  OTHERS_ORDER_INTRO_RULE_MAIN_2_DESCRIPTION_TEXT             : 'When exporting a machine, the purchaser must comply with all environmental and safety regulations of the importing country and take all necessary measures (e.g.,product modification, obtaining government approval, etc.).',
  OTHERS_ORDER_INTRO_RULE_MAIN_3_TEXT                         : 'Terms of Sale',
  OTHERS_ORDER_INTRO_RULE_MAIN_3_SUB_1_DESCRIPTION_1_TEXT     : 'Machines will be sold on a first come, first served basis, with no warranty.',
  OTHERS_ORDER_INTRO_RULE_MAIN_3_SUB_1_DESCRIPTION_2_TEXT     : 'Therefore, it is strongly recommended that you check the actual machine before purchasing it.',
  OTHERS_ORDER_INTRO_RULE_MAIN_3_SUB_1_DESCRIPTION_3_TEXT     : 'Payment must be made in full in cash (Japanese yen) to the bank account designated by us within one week of purchase. (Telegraphic transfer)',
  OTHERS_ORDER_INTRO_RULE_MAIN_3_SUB_1_DESCRIPTION_4_TEXT     : 'Please remove the machine within one month from the date of purchase.',

  OTHERS_PROFILE_TEXT                           : 'Company Outline',
  OTHERS_PROFILE_COMPANY_NAME_LABEL             : 'Name',
  OTHERS_PROFILE_COMPANY_NAME_TEXT              : 'Kobelco Construction Machinery Co., Ltd',
  OTHERS_PROFILE_COMPANY_NAME_OTHER_LABEL       : 'Japanese company name',
  OTHERS_PROFILE_COMPANY_NAME_OTHER_TEXT        : 'コベルコ建機株式会社',
  OTHERS_PROFILE_DIRECTOR_NAME_LABEL            : 'President and CEO',
  OTHERS_PROFILE_DIRECTOR_NAME_TEXT             : 'Akira Yamamoto',
  OTHERS_PROFILE_ADDRESS_LABEL                  : 'Office',
  OTHERS_PROFILE_POST_MARK                      : '',
  OTHERS_PROFILE_OFFICE_KOBE_TEXT               : 'Kobe Used Crane Center',
  OTHERS_PROFILE_OFFICE_KOBE_ADDRESS_TTEXT      : '7-15 Minatojima, Chuo-ku, Kobe City, Hyogo Prefecture',
  OTHERS_PROFILE_OFFICE_KOBE_TEL                : 'Tel : +81-78-303-1220',
  OTHERS_PROFILE_OFFICE_KOBE_FAX                : 'Fax : +81-78-303-1250',
  OTHERS_PROFILE_OFFICE_YOKOHAMA_TEXT           : 'Yokohama Used Crane Center',
  OTHERS_PROFILE_OFFICE_YOKOHAMA_ADDRESS_TTEXT  : '2-32 Daikoku-cho, Tsurumi-ku, Yokohama City, Kanagawa Prefecture',
  OTHERS_PROFILE_OFFICE_YOKOHAMA_TEL            : 'Tel : +81-45-834-9994',
  OTHERS_PROFILE_OFFICE_YOKOHAMA_FAX            : 'Fax : +81-45-510-4126',
  OTHERS_PROFILE_CAPITAL_LABEL                  : 'Capitalization',
  OTHERS_PROFILE_CAPITAL_TEXT                   : '16000 million yen',
  OTHERS_PROFILE_STOCKHOLDER_LABEL              : 'Stockholder',
  OTHERS_PROFILE_STOCKHOLDER_TEXT               : 'Kobe Steel, Ltd 100%',
  OTHERS_PROFILE_FOUNDED_LABEL                  : 'Founded',
  OTHERS_PROFILE_FOUNDED_TEXT                   : 'October 1, 1999',
  OTHERS_PROFILE_LICENSE_LABEL                  : 'Secondhand Dealer License',
  OTHERS_PROFILE_LICENSE_TEXT1                  : 'Approved by the Tokyo Metropolitan Public Safety Commission',

  // Adding company profile
  OTHERS_PROFILE2_COMPANY_NAME_LABEL             : 'Name',
  OTHERS_PROFILE2_COMPANY_NAME_TEXT              : 'Kobelco Construction Machinery Japan Co., Ltd',
  OTHERS_PROFILE2_COMPANY_NAME_OTHER_LABEL       : 'Japanese company name',
  OTHERS_PROFILE2_COMPANY_NAME_OTHER_TEXT        : 'コベルコ建機日本株式会社',
  OTHERS_PROFILE2_DIRECTOR_NAME_LABEL            : 'President and CEO',
  OTHERS_PROFILE2_DIRECTOR_NAME_TEXT             : 'Jiro Araki',
  OTHERS_PROFILE2_ADDRESS_LABEL                  : 'Office',
  OTHERS_PROFILE2_POST_MARK                      : '',
  OTHERS_PROFILE2_OFFICE_ICHIKAWA_TEXT           : 'Ichikawa Used Excavator Center',
  OTHERS_PROFILE2_OFFICE_ICHIKAWA_ADDRESS_TEXT   : '17 Futamata Shinmachi, Ichikawa City, Chiba Prefecture',
  OTHERS_PROFILE2_OFFICE_ICHIKAWA_TEL            : 'Tel : +81-47-327-5505',
  OTHERS_PROFILE2_OFFICE_ICHIKAWA_FAX            : 'Fax : +81-47-327-5512',
  OTHERS_PROFILE2_OFFICE_KOBE_TEXT               : 'Kobe Used Crane Center',
  OTHERS_PROFILE2_OFFICE_KOBE_ADDRESS_TTEXT      : '7-15 Minatojima, Chuo-ku, Kobe City, Hyogo Prefecture',
  OTHERS_PROFILE2_OFFICE_KOBE_TEL                : 'Tel : +81-78-303-0900',
  OTHERS_PROFILE2_OFFICE_KOBE_FAX                : 'Fax : +81-78-303-2622',
  OTHERS_PROFILE2_CAPITAL_LABEL                  : 'Capitalization',
  OTHERS_PROFILE2_CAPITAL_TEXT                   : '490 million yen',
  OTHERS_PROFILE2_STOCKHOLDER_LABEL              : 'Stockholder',
  OTHERS_PROFILE2_STOCKHOLDER_TEXT               : 'Kobelco Construction Machinery Co., Ltd   100％',
  OTHERS_PROFILE2_FOUNDED_LABEL                  : 'Founded',
  OTHERS_PROFILE2_FOUNDED_TEXT                   : 'April 1, 1999',
  OTHERS_PROFILE2_LICENSE_LABEL                  : 'Secondhand Dealer License',
  OTHERS_PROFILE2_LICENSE_TEXT1                  : 'Approved by the Chiba Prefectural Public Safety Commission',

  OTHERS_PRIVACY_TEXT                                         : 'Protection of Personal Information',
  OTHERS_PRIVACY_MAIN_1_TEXT                                  : 'Policy on the Protection of Personal Information',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_1_DESCRIPTION_TEXT           : 'Kobelco Construction Machinery Co., Ltd. and its group companies (hereinafter referred to as "Kobelco") recognize that the proper protection of personal information of customers, business partners, and all other persons who provide personal information to Kobelco through transactions with Kobelco (hereinafter referred to as "the person") is an important responsibility, and in order to fulfill this responsibility, Kobelco handles personal information under the following policies.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_1_SUB_1_TEXT                 : 'In addition to complying with the Act on the Protection of Personal Information (hereinafter referred to as the "Personal Information Protection Act") and other related laws and regulations applicable to personal information, we will handle personal information in an appropriate manner in accordance with generally accepted practices regarding the handling of personal information. We will also strive to improve the handling of such information as appropriate.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_1_SUB_2_TEXT                 : 'We will clarify the rules regarding the handling of personal information and make them known to all employees.The Company will also supervise contractors in a necessary and appropriate manner.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_1_SUB_3_TEXT                 : 'When handling personal information, we will specify the purpose of use, notify or announce it, and handle personal information in accordance with that purpose of use.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_1_SUB_4_TEXT                 : 'In order to prevent leakage, loss, falsification, etc. of personal information, we will take the necessary measures to manage it appropriately.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_1_SUB_5_TEXT                 : 'We will accept requests for disclosure, correction, deletion, or suspension of use of personal information in our possession from the person in question at the designated contact point, and will respond in good faith.',
  OTHERS_PRIVACY_MAIN_2_TEXT                                  : 'Purpose of use of personal information',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_2_DESCRIPTION_TEXT           : 'Kobelco may use personal information obtained by appropriate means in light of laws and regulations, etc., within the scope necessary for the following purposes in our business.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_2_SUB_1_TEXT                 : 'To inform and provide information on products and services',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_2_SUB_2_TEXT                 : 'For the development of products and services',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_2_SUB_3_TEXT                 : 'For the purchase of raw materials, goods, and services',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_2_SUB_4_TEXT                 : 'To respond to inquiries from the person in question',
  OTHERS_PRIVACY_MAIN_3_TEXT                                  : 'Shared use',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_3_DESCRIPTION_TEXT           : 'Kobelco may share personal data items such as names, addresses, telephone numbers, e-mail addresses, product purchase histories, workplaces, and job titles among the group companies described below within the scope of the purposes of use described in the preceding paragraph. Kobelco will be responsible for the shared use of the information, and inquiries and complaints will be accepted at the following contact point.',
  OTHERS_PRIVACY_MAIN_4_TEXT                                  : 'Disclosure and provision to third parties',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_4_DESCRIPTION_TEXT           : 'Kobelco will not disclose or provide personal data to any third party, except in the case of shared use or provision to a contractor as described in the preceding paragraph, or in any of the following cases',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_4_SUB_1_TEXT                 : 'When the person concerned has given consent.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_4_SUB_2_TEXT                 : 'When disclosing or providing statistical data or other information in a manner that does not allow the identification of the person.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_4_SUB_3_TEXT                 : 'When disclosure or provision of information is required by law.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_4_SUB_4_TEXT                 : 'When it is necessary to protect the life, body, or property of an individual and it is difficult to obtain the consent of the person in question.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_4_SUB_5_TEXT                 : 'When it is necessary to cooperate with the national or local government in conducting public affairs, and obtaining the consent of the person in question may interfere with the execution of those affairs.',
  OTHERS_PRIVACY_MAIN_5_TEXT                                  : 'Management on the website',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_DESCRIPTION_TEXT           : 'Stored personal information will be secured to prevent leakage or falsification of information, and in the event of an emergency, measures will be taken as quickly as possible.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_TITLE_1_TEXT           : 'About security',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_DESCRIPTION_1_TEXT     : 'Kobelco has introduced SSL (Secure Sockets Layer) as a measure to protect personal information. SSL is a technology that encodes information flowing over the Internet, preventing it from being leaked to third parties. Pages that handle personal information will be SSL compatible. For SSL-enabled pages, please check that the URL has been changed from "http://" to "https://".',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_TITLE_2_TEXT           : 'About the use of "Cookie"',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_DESCRIPTION_2_TEXT     : "\"Cookie\" may be used to ensure security and to provide appropriate information to customers.\"Cookie\" is a piece of information that is sent from a server to a user's browser, and may be stored on the user's computer hard disk, etc. \"Cookie\" do not contain any information that can be used to identify you. You can decide whether to accept or reject \"Cookie\" by setting your browser. However, if you refuse to accept \"Cookie\", you may not be able to use some of the services on the sites operated by the Company.",
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_TITLE_3_TEXT           : 'About browsing history',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_DESCRIPTION_3_TEXT     : 'When you use a site operated by Kobelco, the access status will be stored on the server. This information will be used for statistical purposes, such as measuring access volume, and will not be used for any other purpose.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_TITLE_4_TEXT           : 'Using Google Analytics',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_DESCRIPTION_4_1_TEXT   : 'Kobelco uses Google Analytics of Google Inc. to understand the access status and usage trends of the sites we operate.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_5_SUB_DESCRIPTION_4_2_TEXT   : 'Google Analytics uses "Cookie" to collect data without including any information that can identify you. Information on how Google Analytics collects and processes information can be found in the policies of Google Inc.',
  OTHERS_PRIVACY_MAIN_6_TEXT                                  : 'Disclosure',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_6_DESCRIPTION_TEXT           : 'If you wish to disclose your own personal information regarding the personal data held by Kobelco, Kobelco will respond to your request within a reasonable period and scope after confirming the identity of the person making the request and determining that disclosure is necessary.',
  OTHERS_PRIVACY_MAIN_7_TEXT                                  : 'Correction, deletion, etc',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_7_DESCRIPTION_TEXT           : 'If you wish to have your personal information corrected, added, or deleted, we will do so within a reasonable period of time and to a reasonable extent, after confirming the identity of the person making the request and determining that the information is not true and needs to be corrected, added, or deleted.',
  OTHERS_PRIVACY_MAIN_8_TEXT                                  : 'Suspension of use and delete',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_8_DESCRIPTION_TEXT           : 'With regard to the personal data held by Kobelco, if you wish Kobelco to stop using or erase your own information, Kobelco will stop using or erase it within a reasonable period and scope after confirming the identity of the person making the request and determining that it is necessary to stop using or erase it in accordance with the provisions of Article 27 of the Personal Information Protection Law. If we stop using or delete some or all of this information, we may be unable to provide services that meet your requests, and we ask for your understanding and cooperation in this matter.（Please note that we may not be able to respond to requests for suspension of use or deletion of information held in accordance with relevant laws and regulations.）',
  OTHERS_PRIVACY_MAIN_9_TEXT                                  : 'Contact for disclosure, etc.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_DESCRIPTION_TEXT           : "Requests regarding Kobelco's retained personal data as described in 6, 7, and 8 above and other inquiries regarding personal information are accepted at the following contact point.",
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_SUB_1_TEXT                 : 'Reception counter',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_SUB_2_TEXT                 : 'We will accept your personal information at the contact point that we notified you of when you provided us with your personal information, or at your business contact point if you have a business relationship with Kobelco.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_SUB_3_TEXT                 : 'Details of the procedures for verifying the identity of the applicant and the fees will be provided upon request.If you are unable to find the contact information, please contact the following.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_TH_1_TEXT                  : 'Contact us',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_TD_1_TEXT                  : 'Kobelco Construction Machinery Co., Ltd   Planing Division Corporate Comunication Group',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_TH_2_TEXT                  : 'Tel',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_TH_4_TEXT                  : 'MAIL',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_SUB_4_TEXT                 : 'Developed: 1 April 2016',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_SUB_5_TEXT                 : 'Revised: July 5, 2018',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_SUB_6_TEXT                 : 'Kobelco Construction Machinery Co.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_9_SUB_7_TEXT                 : 'President and CEO　Akira Yamamoto',
  OTHERS_PRIVACY_MAIN_10_TEXT                                 : 'Kobelco Group Companies',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_SUB_TITLE_1_TEXT          : 'Major domestic corporations',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_1_SUB_DESCRIPTION_1_TEXT  : 'Kobelco Construction Machinery Japan Co.,Ltd.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_1_SUB_DESCRIPTION_2_TEXT  : 'Kobelco Constrution Machinery Engineering Co., Ltd.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_1_SUB_DESCRIPTION_3_TEXT  : 'Kobelco Training Services Co., Ltd.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_1_SUB_DESCRIPTION_4_TEXT  : 'Kobelco Construction Machinery Total Support Co., Ltd.',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_SUB_TITLE_2_TEXT          : 'Major Overseas Corporations',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_1_TEXT  : '神鋼建機（China）有限公司（China）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_2_TEXT  : '成都神鋼建機融資租賃有限公司（China）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_3_TEXT  : '杭州神鋼建設機械有限公司（China）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_4_TEXT  : 'Kobelco Construction Machinery Southeast Asia Co., Ltd.（Thailand）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_5_TEXT  : 'Kobelco International (S) Co., Pte. Ltd.（Singapore）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_6_TEXT  : 'Ricon Private Ltd.（Singapore）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_7_TEXT  : 'PT. Daya Kobelco Construction Machinery Indonesia（Indonesia）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_8_TEXT  : 'Kobelco Construction Machinery Malaysia Sdn. Bhd.（Malaysia）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_9_TEXT  : 'Kobelco Construction Equipment India Pvt. Ltd.（India）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_10_TEXT : 'Kobelco Construction Machinery Australia Pty. Ltd.（Australia）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_11_TEXT : 'Kobelco Construction Machinery Middle East & Africa Fzco.（U.A.E）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_12_TEXT : 'Kobelco Construction Machinery U.S.A. Inc.（America）',
  OTHERS_PRIVACY_INTRO_RULE_MAIN_10_2_SUB_DESCRIPTION_13_TEXT : 'Kobelco Construction Machinery Europe B.V.（The Netherlands）',

  GUIDE_PAGE_TITLE_1                  : 'Web Auctions',
  GUIDE_PAGE_TITLE_2                  : 'tender/Flow of business discussion',
  GUIDE_PAGE_STEP1_TITLE              : 'Membership Registration',
  GUIDE_PAGE_STEP1_DESCRIPTION_1      : 'If you would like to participate in the bidding, please apply for membership on the website.',
  GUIDE_PAGE_STEP1_DESCRIPTION_2      : 'After receiving your application, we will review it.Those who have completed the examination will be sent a login ID by e-mail.',
  GUIDE_PAGE_STEP1_DESCRIPTION_3      : 'Please note that you will be asked to submit the following documents for examination.',
  GUIDE_PAGE_STEP1_NOTE_TITLE_1       : 'Examination Pass',
  GUIDE_PAGE_STEP1_NOTE_DESCRIPTION_1 : 'Eligibility',
  GUIDE_PAGE_STEP1_NOTE_DESCRIPTION_2 : 'You must be a member, understand the bidding guidelines and export control considerations, and have passed our examinations.',

  GUIDE_PAGE_STEP2_TITLE : 'Search for Construction Machinery',
  GUIDE_PAGE_STEP2_TAB_1 : 'auction',
  GUIDE_PAGE_STEP2_TAB_2 : 'tender',
  GUIDE_PAGE_STEP2_TAB_3 : 'Stock machine negotiations',

  GUIDE_PAGE_STEP2_TAB_1_TITLE_1         : 'Browsing and Bidding Periods',
  GUIDE_PAGE_STEP2_TAB_1_1_DESCRIPTION_1 : 'There is a "browsing period" during which you can check the information on the exhibited construction equipment in advance, and a "bidding period" during which you can actually place bids.',
  GUIDE_PAGE_STEP2_TAB_1_1_DESCRIPTION_2 : 'You can favorite in the "browse period" as well, so please take a look.',
  GUIDE_PAGE_STEP2_TAB_1_1_DESCRIPTION_3 : 'View the schedule',
  GUIDE_PAGE_STEP2_TAB_1_2_TITLE_1       : 'What is Auction?',
  GUIDE_PAGE_STEP2_TAB_1_2_DESCRIPTION_1 : 'This is a "bidding up" system where you can bid on any item at the current price displayed + the amount per bidding unit.',
  GUIDE_PAGE_STEP2_TAB_1_3_TITLE_3       : 'Extension of end time',
  GUIDE_PAGE_STEP2_TAB_1_3_DESCRIPTION_1 : 'If there is a bid that changes the current price within N minutes before the end of the auction, the time will be extended only for that machine.（Whether or not there is an extension, and the value of the N minutes just before the end of the auction, will vary from auction to auction.）',
  GUIDE_PAGE_STEP2_TAB_1_4_TITLE_4       : 'Method for determining the winning bid price',
  GUIDE_PAGE_STEP2_TAB_1_5_TITLE_5       : 'Automatic bidding',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_1 : 'The bidder with the highest price wins.The winning bid is automatically determined by adding one bidding unit to the bid price of the second highest bidder.',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_2 : 'e.g.）Second highest bid of JPY 6,500,000 ＋ 1 bid unit（JPY 100,000）＝ Sold JPY 6,600,000',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_3 : 'e.g.：In the case of Starting price JPY 4,000,000, ',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_4 : 'Minimum bid price JPY 6,000,000, ',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_5 : '1 bid unit JPY 100,000.',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_6 : 'Bid example',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_7 : 'The second highest bid price of JPY 6,500,000 + 1 bid unit (JPY 100,000) will be displayed as the current price.',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_8 : 'If the auction ends in this manner, the successful bidder will be Member A and the winning bid will be JPY 6,600,000.',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_9 : 'Browse the list of auctioned machines',
  GUIDE_PAGE_STEP2_TAB_2_TITLE_1         : 'Browsing and Bidding Periods',
  GUIDE_PAGE_STEP2_TAB_2_1_DESCRIPTION_1 : 'There is a "browsing period" during which you can check the information on the exhibited construction equipment in advance, and a "bidding period" during which you can actually place bids.',
  GUIDE_PAGE_STEP2_TAB_2_1_DESCRIPTION_2 : 'You can favorite in the "browse period" as well, so please take a look.',
  GUIDE_PAGE_STEP2_TAB_2_1_DESCRIPTION_3 : 'View the schedule',
  GUIDE_PAGE_STEP2_TAB_2_2_TITLE_1       : 'What is the Tender?',
  GUIDE_PAGE_STEP2_TAB_2_2_DESCRIPTION_1 : 'The current price will be kept secret, and the highest bidder at the end of the auction will be the winner of the "sealed" auction.',
  GUIDE_PAGE_STEP2_TAB_2_3_TITLE_3       : 'Extension of end time',
  GUIDE_PAGE_STEP2_TAB_2_3_DESCRIPTION_1 : 'There is no extension for the tender method.',
  GUIDE_PAGE_STEP2_TAB_2_4_TITLE_4       : 'Method for determining the winning bid price',
  GUIDE_PAGE_STEP2_TAB_2_5_DESCRIPTION_1 : 'Bid amount = TOP bid amount',
  GUIDE_PAGE_STEP2_TAB_2_5_DESCRIPTION_2 : 'The bidder with the highest price wins the auction.',
  GUIDE_PAGE_STEP2_TAB_2_5_DESCRIPTION_3 : '※If there is more than one bidder with the highest price, the bidder with the earlier bid will be the successful bidder.',
  GUIDE_PAGE_STEP2_TAB_2_5_DESCRIPTION_4 : 'Browse the list of Tender Exhibitors',
  GUIDE_PAGE_STEP2_TAB_3_TITLE_1         : 'When to hold',
  GUIDE_PAGE_STEP2_TAB_3_1_DESCRIPTION_1 : 'Please check the website for more information on this event.',
  GUIDE_PAGE_STEP2_TAB_3_TITLE_2         : 'About Price',
  GUIDE_PAGE_STEP2_TAB_3_1_DESCRIPTION_2 : 'If it is not listed on the site, please contact us through the Contact Us page.',
  GUIDE_PAGE_STEP2_TAB_3_1_DESCRIPTION_3 : 'Browse the list of machines in stock',
  GUIDE_PAGE_STEP3_TITLE                 : 'Inquire about construction machinery',
  GUIDE_PAGE_STEP3_DESCRIPTION_1         : 'If you have any questions about the machine, please contact us using the inquiry form.',
  GUIDE_PAGE_STEP3_DESCRIPTION_2         : '※If you wish to check the actual machine, please contact the respective office in advance.',
  GUIDE_PAGE_STEP4_TITLE                 : 'Closing a business deal',
  GUIDE_PAGE_STEP4_DESCRIPTION_1         : 'If you have participated in the auction or tender, you will be contacted by email with the results of your bid.',
  GUIDE_PAGE_STEP4_DESCRIPTION_2         : 'If you have not heard from us by the result announcement date, please contact us.',
  GUIDE_PAGE_STEP5_TITLE                 : 'Payment',
  GUIDE_PAGE_STEP5_DESCRIPTION_1         : 'Payment must be made in cash (by wire transfer) within one week of receiving the invoice.',
  GUIDE_PAGE_STEP5_DESCRIPTION_2         : 'N.B.）Cancellation of bids after bidding will not be accepted for any reason.',
  GUIDE_PAGE_STEP5_DESCRIPTION_3         : 'If a bidder cancels his bid unreasonably after winning, he will be required to pay 20% of the winning bid price as a penalty.',
  GUIDE_PAGE_STEP6_TITLE                 : 'Trading and carry out',
  GUIDE_PAGE_STEP6_DESCRIPTION_1         : 'The machine can be picked up and carried out after we confirm the payment to us.',
  GUIDE_PAGE_STEP6_DESCRIPTION_2         : 'The machine must be picked up and carried out within one month of notification of winning the bid.',
  GUIDE_PAGE_STEP6_DESCRIPTION_3         : 'Warranty',
  GUIDE_PAGE_STEP6_DESCRIPTION_4         : 'The product is sold "as is" with no warranty after purchase.',
  GUIDE_PAGE_STEP6_DESCRIPTION_5         : 'The specifications and conditions listed in the list of machines up for bid are for reference only, and you are responsible for checking the actual machine yourself.',

  FAQ_TITLE_TEXT                         : 'Q&A',
  FAQ_INTRO_QA_TEXT                      : 'Please check as follow Q&A, before contact us.',
  FAQ_LIST_QA_1_TITLE_TEXT               : 'I\'d like to inspect and ckeck some machines by myself. Is it OK?',
  FAQ_LIST_QA_1_CONTENT_TEXT1            : 'Yes, it\'s OK. But please call us before coming to our yard for caution\'s safe, since we move the machine for our convenience without any notice.',
  FAQ_LIST_QA_1_CONTENT_TEXT2            : 'You can not inspect/check for sold machines.',
  FAQ_LIST_QA_2_TITLE_TEXT               : 'Can I reserve machine(s) for my purchase?',
  FAQ_LIST_QA_2_CONTENT_TEXT             : 'No. Our sales is subject to prior-sales.',
  FAQ_LIST_QA_3_TITLE_TEXT               : 'Can I have a machine inspection report?',
  FAQ_LIST_QA_3_CONTENT_TEXT             : 'We can inform of machine condition report which had been done when the machine arrived in our yard.<br class="only_pc">Please note that this is not to guarantee the machine condition stated or non-stated in the report.<br>We recommend you to inspect and check the machine by yourself.',
  FAQ_LIST_QA_4_TITLE_TEXT               : 'Are all the machines stocked in Kobe yard or Ichikawa yard?',
  FAQ_LIST_QA_4_CONTENT_TEXT             : 'Machines are normally stocked in Kobe yard or Ichikawa yard.<br>However, some machines may be stocked in other yard.<br>We recommend you to ask previously our staff about its location.',
  FAQ_LIST_QA_5_TITLE_TEXT               : 'Is the machine warrantied?',
  FAQ_LIST_QA_5_CONTENT_TEXT             : 'There is neither warranties nor guarantees. The machine is sold "As it is".',
  FAQ_LIST_QA_6_TITLE_TEXT               : 'What is the payment terms?',
  FAQ_LIST_QA_6_CONTENT_TEXT             : '100% of advanced payment in Japanese Yen & within fourteen (14) calendar days after issuing Pro-Forma Invoice by our company.<br>All banking charges incurred shall be the account of you.',
  FAQ_LIST_QA_7_TITLE_TEXT               : 'Can I self collect the machine(s) from your yard by myself?',
  FAQ_LIST_QA_7_CONTENT_TEXT             : 'Yes, you may arrange for self-collection. However, in case of EX Works, Japanese regulations require us to collect consumption tax imposed by the Government. ',
  FAQ_LIST_QA_8_TITLE_TEXT               : 'Can you transport the machine to the domestic designated area?',
  FAQ_LIST_QA_8_CONTENT_TEXT             : 'Yes, we can. We will inform you of the cost and expenses to be born by you separately.',
  FAQ_LIST_QA_9_TITLE_TEXT               : 'When can I come to pick the purchased machine up?',
  FAQ_LIST_QA_9_CONTENT_TEXT             : 'We can deliver the machine after our receipt of full payment. Please inform us of picking up date & time and your transportation Company Name etc in advance.',
  FAQ_LIST_QA_10_TITLE_TEXT              : 'Is there a time limit for storage?',
  FAQ_LIST_QA_10_CONTENT_TEXT            : 'Please carry the machine(s) out of our yard within one(1) month from your purchasing.',
  FAQ_LIST_QA_11_TITLE_TEXT              : 'Can you accept for returning the machine(s)?',
  FAQ_LIST_QA_11_CONTENT_TEXT            : 'No cancellation is not allowed.',
  FAQ_LIST_QA_12_TITLE_TEXT              : 'I\'m considering purchasing a new car for domestic use.',
  FAQ_LIST_QA_12_CONTENT_TEXT            : 'Please contact your nearest sales office. ',
  FAQ_LINK_SALES_TEXT                    : 'Domestic sales office （Go to a Home Page of KOBELCO CONSTRUCTION MACHINERY CO., LTD. ）',

  ACCESS_SHOP_LIST_TITLE_TEXT                      : 'Access Map',
  ACCESS_SHOP_LIST_ADDRESS_TITLE_TEXT              : 'Address',
  ACCESS_SHOP_LIST_TEL_TITLE_TEXT                  : 'Tel',
  ACCESS_SHOP_LIST_FAX_TITLE_TEXT                  : 'Fax',
  ACCESS_WORKING_TIME_TITLE                        : 'Business day',
  ACCESS_TOKUCHO_TITLE                             : 'Characteristic',
  ACCESS_BY_CAR_TITLE                              : 'Address by Car',
  ACCESS_BY_CAR_MAP_ALT                            : 'Access Map',
  ACCESS_BY_TRAIN_TITLE                            : 'Address by Train',
  ACCESS_SHOP_LIST_COMPANY_TEXT                    : 'Kobelco Construction Machinery Co., Ltd.',
  ACCESS_SHOP_LIST_COMPANY_JAPAN_TEXT              : 'Kobelco Construction Machinery Japan Co., Ltd.',
  ACCESS_SHOP_LIST_ICHIKAWA_INTRO_TEXT             : 'Used Excavator Machinery Center',
  ACCESS_SHOP_LIST_ICHIKAWA_TEXT                   : 'Ichikawa Used Machinery Center',
  ACCESS_SHOP_LIST_ICHIKAWA_EXPLAIN_TEXT           : 'The location adjoin Parts center, Maintenance facility, and Training school.<br><br>',
  ACCESS_SHOP_LIST_ICHIKAWA_ADDRESS_CONTENT_TEXT   : '17, Futamatashinmachi, Ichikawa City, Chiba<br>Post code: 272-0002',
  ACCESS_SHOP_LIST_ICHIKAWA_TEL_CONTENT_TEXT       : '+81-47-327-5505',
  ACCESS_SHOP_LIST_ICHIKAWA_TEL_HREF_TEXT          : 'tel:+81-47-327-5505',
  ACCESS_SHOP_LIST_ICHIKAWA_FAX_CONTENT_TEXT       : '+81-47-327-5512',
  ACCESS_SHOP_LIST_ICHIKAWA_ACCESS_TEXT            : 'Ichikawa Used Machinery Center',
  ACCESS_SHOP_LIST_YOKOHAMA_INTRO_TEXT             : 'Used Cranes Machinery Center',
  ACCESS_SHOP_LIST_YOKOHAMA_TEXT                   : 'Yokohama Used Machinery Center',
  ACCESS_SHOP_LIST_YOKOHAMA_EXPLAIN_TEXT           : 'The Yokohama stock yard is located together with a Kobelco service factory and a brand new crane sales office in the same site.',
  ACCESS_SHOP_LIST_YOKOHAMA_ADDRESS_CONTENT_TEXT   : '2-32 Daikokucho, Tsurumi-ku, Yokohama City, Kanagawa<br>Post code: 230-0053',
  ACCESS_SHOP_LIST_YOKOHAMA_TEL_CONTENT_TEXT       : '+81-45-834-9994',
  ACCESS_SHOP_LIST_YOKOHAMA_TEL_HREF_TEXT          : 'tel:+81-45-834-9994',
  ACCESS_SHOP_LIST_YOKOHAMA_FAX_CONTENT_TEXT       : '+81-45-510-4126',
  ACCESS_SHOP_LIST_YOKOHAMA_ACCESS_TEXT            : 'Yokohama Used Machinery Center',
  ACCESS_SHOP_LIST_KOBE_INTRO_TEXT                 : 'Kobelco Construction Machinery Co., Ltd. Used Excavators & Cranes Machinery Center',
  ACCESS_SHOP_LIST_KOBE_INTRO_TEXT2                : 'Kobelco Construction Machinery Japan Co., Ltd. Used & Rental Machinery Center',
  ACCESS_SHOP_LIST_KOBE_TEXT                       : 'Kobe Used Machinery Center',
  ACCESS_SHOP_LIST_KOBE_EXPLAIN_TEXT               : 'It will support all KOBELCO used construction machinery customer\'s needs.',
  ACCESS_SHOP_LIST_KOBE_ADDRESS_CONTENT_TEXT       : '7-15, Minatojima, Chuo-ku, Kobe City, Hyogo<br>Post code: 650-0045',
  ACCESS_SHOP_LIST_KOBE_TEL1_CONTENT_TEXT          : '+81-78-303-0900',
  ACCESS_SHOP_LIST_KOBE_TEL1_HREF_TEXT             : 'tel:+81-78-303-0900',
  ACCESS_SHOP_LIST_KOBE_TEL1_TITLE                 : '(Excavators sales dept.)',
  ACCESS_SHOP_LIST_KOBE_TEL2_CONTENT_TEXT          : '+81-78-303-1220',
  ACCESS_SHOP_LIST_KOBE_TEL2_HREF_TEXT             : 'tel:+81-78-303-1220',
  ACCESS_SHOP_LIST_KOBE_TEL2_TITLE                 : '(Cranes sales dept.)',
  ACCESS_SHOP_LIST_KOBE_FAX_CONTENT_TEXT           : '+81-78-303-2622（Excavators sales dept.）<br>+81-78-303-1250（Cranes sales dept.）',
  ACCESS_SHOP_LIST_KOBE_ACCESS_TEXT                : 'Kobe Used Machinery Center',
  ACCESS_SHOP_LIST_ABOUT_USED_TEXT                 : 'Introduction of Used Machinery Center',
  ACCESS_SHOP_LIST_ABOUT_USED1_TEXT                : 'We support used construction machinery all over Japan.',
  ACCESS_SHOP_LIST_ABOUT_USED2_TEXT                : 'Kobelco is the manufacturer of construction machineries and proud of its high performance (low fuel consumption, low emission, low noise etc.), quality, durability and so on which are highly appraised by the global used construction machinery market as well.',
  ACCESS_SHOP_LIST_ABOUT_USED3_TEXT                : 'The used constrution equipment divisions of Kobelco Construction Machinery Co., Ltd. (hereinafter referred to us "KCM") and Kobelco Construction Machinery Japan Co., Ltd. (hereinafter referred to us "KCMJ") trade Kobelco brand used machinery worldwide. Our group operates used machinery centers in three locations across the country (Ichikawa and Yokohama in the east, and Kobe in the west), where customers select, inspect, and purchase machines. Our group also holds regular auctions and publishes stock information on its website.',
  ACCESS_SHOP_LIST_ABOUT_USED4_TEXT                : 'KCM and KCMJ used machinery division welcome and appreciate customers visit and access to HP at any time.',
  ACCESS_SHOP_LIST_ABOUT_USED_CENTER_TEXT          : 'Introduction of Used Machinery Center',

  ACCESS_ICHIKAWA_CENTER_TITLE                     : 'Ichikawa Used Machinery Center',
  ACCESS_ICHIKAWA_ADDRESS                          : '17, Futamatashinmachi, Ichikawa City, Chiba<br>Post code: 272-0002',
  ACCESS_ICHIKAWA_TEL                              : '+81-47-327-5505',
  ACCESS_ICHIKAWA_TEL_HREF                         : 'tel:+81-47-327-5505',
  ACCESS_ICHIKAWA_FAX                              : '+81-47-327-5512',
  ACCESS_ICHIKAWA_WORKING_TIME                     : '8:30-17:00<br>From Monday to Friday (Japanese holidays will be closed)<br>Please take an appointment in advance.',
  ACCESS_ICHIKAWA_TOKUCHO_TITLE                    : 'Characteristic',
  ACCESS_ICHIKAWA_TOKUCHO                          : 'The location adjoin Parts center, Maintenance facility, and Training school.',
  ACCESS_ICHIKAWA_CENTER_TEXT                      : 'Ichikawa Used Machinery Center',
  ACCESS_ICHIKAWA_BY_CAR_MAP_DIRECT1               : 'Keiyo Expressway<br>From Tokyo/Chiba:Approx 10 min drive from Baraki IC',
  ACCESS_ICHIKAWA_BY_CAR_MAP_DIRECT2               : 'Higashi Kanto Expressway<br>From Tokyo:Approx 15 min drive from Chidoricho IC<br>From Chiba:Approx 25 min drive from Wangan Narashino IC',
  ACCESS_ICHIKAWA_BY_SHINKANSEN                    : 'Access from Shinkansen Tokyo Station',
  ACCESS_ICHIKAWA_BY_SHINKANSEN_MAP_DIRECT1        : 'JR Keiyo Line[Tokyo Station]-[Futamata Shinmachi Station]<br>Approx 10 min walk to ICHIKAWA Yard from Futamata Shinmachi Station',
  ACCESS_ICHIKAWA_BY_SHINKANSEN_MAP_DIRECT2        : 'Tokyo Metro Tozai Line[Otemachi Station]-[Nishi Funabashi Station]<br>Approx 10 min / JPY 1,500 to ICHIKAWA Yard from Nishi Funabashi Station by Taxi',
  ACCESS_ICHIKAWA_BY_SHINKANSEN_MAP_DIRECT3_LINK1  : 'Access from JR Tokyo Station or Access from Narita Airport',
  ACCESS_ICHIKAWA_BY_SHINKANSEN_MAP_DIRECT3_LINK2  : 'Access by using Tokyo Metro Tozai Line',
  ACCESS_ICHIKAWA_BY_AIRPORT_HANEDA                : 'Access from Haneda Airport',
  ACCESS_ICHIKAWA_BY_AIRPORT_HANEDA_MAP_DIRECT     : 'Haneda Airport-(Limousine Bus)-Tokyo Disney Resort-(Transit to train)-JR Keiyo Line<br>[Maihama Station]-[Futamata Shinmachi Station]<br>Approx 10 min walk from Futamata Shinmachi Station to ICHIKAWA Yard.',
  ACCESS_ICHIKAWA_BY_AIRPORT_NARITA                : 'Access from Narita Airport',
  ACCESS_ICHIKAWA_BY_AIRPORT_NARITA_MAP_DIRECT     : 'Narita Airport-(Limousine Bus)-Makuhari Bay Town-(Transit to train)-JR Keiyo Line<br>[Kaihin Makuhari Station]-[Futamata Shinmachi Station]<br>Approx 10 min walk from Futamata Shinmachi Station to ICHIKAWA Yard.',
  ACCESS_ICHIKAWA_HOTEL_TITLE                      : 'Hotel Information around ICHIKAWA Yard',
  ACCESS_ICHIKAWA_HOTEL_NAME                       : 'Oriental Hotel tokyo bay',

  ACCESS_YOKOHAMA_CENTER_TITLE                     : 'Yokohama Used Machinery Center',
  ACCESS_YOKOHAMA_ADDRESS                          : '2-32 Daikokucho, Tsurumi-ku, Yokohama City, Kanagawa<br>Post code: 230-0053',
  ACCESS_YOKOHAMA_TEL                              : '+81-45-834-9994',
  ACCESS_YOKOHAMA_TEL_HREF                         : '+81-45-834-9994',
  ACCESS_YOKOHAMA_FAX                              : '+81-45-510-4126',
  ACCESS_YOKOHAMA_WORKING_TIME                     : 'From Monday to Friday (Japanese holidays will be closed)<br>Please take an appointment in advance.',
  ACCESS_YOKOHAMA_TOKUCHO                          : 'The Yokohama stock yard is located together with a Kobelco service factory and a brand new crane sales office in the same site.',
  ACCESS_YOKOHAMA_BY_CAR_MAP_DIRECT                : '8-minute drive from Namamugi Exit of Kanagawa Route 1 Yokohane Line<br>15-minute drive from Shioiri Exit of Kanagawa Route 1 Yokohane Line',
  ACCESS_YOKOHAMA_BY_TRAIN_DIRECTION1              : '15-minute taxi ride from Turumi Sta.(JR/ Keikyu)',
  ACCESS_YOKOHAMA_BY_TRAIN_DIRECTION2              : '20-minute walk from Namamugi Sta.of Keikyu Line',

  ACCESS_KOBE_CENTER_TITLE                     : 'Kobe Used Machinery Center',
  ACCESS_KOBE_ADDRESS                          : '7-15, Minatojima, Chuo-ku, Kobe City, Hyogo<br>Post code: 650-0045',
  ACCESS_KOBE_TEL1_NAME                        : '(Excavators sales dept.)',
  ACCESS_KOBE_TEL1                             : '+81-78-303-0900',
  ACCESS_KOBE_TEL1_HREF                        : 'tel:+81-78-303-0900',
  ACCESS_KOBE_TEL2_NAME                        : '(Cranes sales dept.)',
  ACCESS_KOBE_TEL2                             : '+81-78-303-1220',
  ACCESS_KOBE_TEL2_HREF                        : 'tel:+81-78-303-1220',
  ACCESS_KOBE_FAX                              : '+81-78-303-2622（Excavators sales dept.）<br>+81-78-303-1250（Cranes sales dept.）',
  ACCESS_KOBE_WORKING_TIME                     : '8:30-17:00<br>From Monday to Friday (Japanese holidays will be closed)<br>Please contact our office in advance.',
  ACCESS_KOBE_TOKUCHO                          : '･Optimum location for export<br>･All Equipments on exhibit in Kobe yard is washed and clean.',
  ACCESS_KOBE_BY_CAR_MAP_DIRECTION1            : 'From the direction of Osaka:Approx 10 min drive from Ikutagawa IC',
  ACCESS_KOBE_BY_CAR_MAP_DIRECTION2            : 'From the direction of Himeji:Approx 10 min drive from Kyobashi IC<br>Please cross Kobe Ohashi Bridge, and turn to left at the exit of the bridge, turn to right at the first traffic light, turn to left at the second traffic light, and go straight, then you will find "KOBELCO" signboard on your right.',
  ACCESS_KOBE_BY_TRAIN1                        : 'Access from Shin-kobe Station(shinkansen)',
  ACCESS_KOBE_BY_TRAIN1_DIRECTION1             : 'Approx 20 min / JPY 2,000 by Taxi to KOBE Yard<br>*Please tell taxi driver to take you to L-8 berth(Naka Futo of Port-Island)',
  ACCESS_KOBE_BY_TRAIN1_DIRECTION2             : 'Information about MK TAXI',
  ACCESS_KOBE_BY_TRAIN2                        : 'Access from Sannomiya Station(JR Kobe Line, Hanshin, Kobe City Subway) to KOBE Yard',
  ACCESS_KOBE_BY_TRAIN2_DIRECTION1             : 'Approx 15 min / JPY 1,700 by Taxi to KOBE Yard<br>*Please tell taxi driver to take you to L-8 berth(Naka Futo of Port-Island)',
  ACCESS_KOBE_BY_TRAIN2_DIRECTION2             : 'Please get off at Naka Futo Station.<br>Approx 14 min from Sannomiya Station to Naka Futo Station by Port Liner,<br>and 20 min walk from Naka Futo Station to KOBE Yard',
  ACCESS_KOBE_BY_TRAIN2_DIRECTION3_A1          : 'Access from JR Sannomiya Station',
  ACCESS_KOBE_BY_TRAIN2_DIRECTION3_A2          : 'Access from Sannomiya Station / Kobe Airport by using Port Liner ',
  ACCESS_KOBE_BY_AIRPORT1                      : 'Access from Kanzai International Airport & Osaka International Airport to Sannomiya Station',
  ACCESS_KOBE_BY_AIRPORT1_DIRECTION1           : 'Kansai International Airport-Sannomiya<br>Approx 65 min by Limousine Bus<br>Osaka International Airport -Sannomiya<br>Approx 40 min by Limousine Bus',
  ACCESS_KOBE_BY_AIRPORT1_DIRECTION2           : 'Information about Kansai International Airport',
  ACCESS_KOBE_BY_AIRPORT2                      : 'Access from Kobe Airport',
  ACCESS_KOBE_BY_AIRPORT2_DIRECTION1           : 'Approx 10 min / JPY 1,500 by Taxi<br>*Please tell taxi driver to take you to L-8 berth(Naka Futo of Port-Island)',
  ACCESS_KOBE_BY_AIRPORT2_DIRECTION2           : 'Using Port Liner, please get off Naka Futo Station.<br>Approx 12 min from Kobe Airport Station to Naka Futo Station by Port Liner,<br>and 20 min walk from Naka Futo Station to KOBE Yard',
  ACCESS_KOBE_HOTEL_TITLE                      : 'Hotel Information around KOBE Yard',
  ACCESS_KOBE_HOTEL1_NAME                      : 'KOBE PORTPIA HOTEL',
  ACCESS_KOBE_HOTEL2_NAME                      : 'HOTEL PEARL CITY KOBE',

  SYSTEM_ERROR_MESSAGE : 'A system error has occurred.'
}
