<template>
<div>
  <Register v-if="editFlag" :parent="$data" />
  <Completion v-else-if="completedFlag" :parent="$data" />
  <Comfirm v-else :parent="$data" />
</div>
</template>

<script>
import Methods from '@/api/methods';
import Comfirm from '../components/RegisterMember/Comfirm';
import Completion from '../components/RegisterMember/Completion';
import Register from '../components/RegisterMember/Register';
export default {
  components : {
    Comfirm,
    Register,
    Completion
  },
  data() {
    return {
      mode         : 'RegisterMember',
      registerData : {
        address          : '',
        antiqueFilePath  : [],
        exportFilePath   : [],
        nameCardFilePath : []
      },
      constants       : [],
      samleFiles      : [],
      sampleFile1     : {},
      sampleFile2     : {},
      sampleFile3     : {},
      editFlag        : true,
      completedFlag   : false,
      selectedCountry : {},
      exportFiles     : [],
      antiqueFiles    : [],
      nameCardFiles   : [],
      confirmEmail    : null,
      confirmPassword : null,
      formConstant    : {},
      membershipTerms : {}
    }
  },
  methods : {
    getMemberRegisterConstants() {
      this.$store.state.loading = true
      Methods.apiExcute('get-member-regist-constants', this.$i18n.locale).then(data => {
        this.$store.state.loading = false
        this.constants = data
        this.samleFiles = this.padArray(this.constants.filter(constant => constant.key_string === 'MEMBER_REQUEST_SAMPLE_FILE'), 3)
        this.sampleFile1 = this.samleFiles[0]
        this.sampleFile2 = this.samleFiles[1]
        this.sampleFile3 = this.samleFiles[2]
        this.formConstant = this.constants.find(constant => constant.key_string === 'MEMBER_REQUEST_FORM') || {}
        this.membershipTerms = this.constants.find(constant => constant.key_string === 'MEMBERSHIP_TERMS') || {}
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    padArray(array, length) {
      for (let i = 0;i < length;i++) {
        array.push({})
      }
      array.splice(length)
      return array
    }
  },
  created() {
    this.getMemberRegisterConstants()
  },
  watch : {
    '$i18n.locale'() {
      this.getMemberRegisterConstants()
    }
  }
}
</script>
