<template>
  <div class="info-box-notice">
    <h2>{{$t("TOP_PAGE_NOTICES_VIEW_HEADER")}}</h2>
    <div class="info-item">
      <a class="item-tag" v-for="item in newNotices" :key="item.notice_no">
        <span class="notice-day">{{item.create_date}}</span>
        <div class="notice-body" v-html="item.body"></div>
        <div v-if="item.display_code == 1">
          <a v-for="file in item.file" class="notice-file" :key="file" :href="'./' + file" ><Icon icon="akar-icons:file"/>{{file.replace(/^.*[\\\/]/, '')}}</a>
        </div>
        <div v-else>
          <a v-for="file in item.file" class="notice-file"  :key="file" @click="downloadFile(file)" ><Icon icon="akar-icons:file"/>{{file.replace(/^.*[\\\/]/, '')}}</a>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import Methods from '@/api/methods'
import { Icon } from '@iconify/vue2'
export default {
  components: {
    Icon
  },
  props: {
    parent: {
      type: Object,
      default: Object
    }
  },
  data() {
    return {
      newNotices: []
    }
  },
  created() {
    this.getNewNotices()
  },
  methods: {
    getNewNotices() {
      this.$store.state.loading = true
      Methods.apiExcute('public/get-new-notices', this.$i18n.locale).then(data => {
        this.$store.state.loading = false
        this.newNotices = data
      }).catch((error) => {
        this.$store.state.loading = false
        this.errors = Methods.parseHtmlResponseError(this.$router, error)
      })
    },
    downloadFile(key) {
      this.$store.state.loading = true
      Methods.apiExcute('private/download-file', this.$i18n.locale, {
        key: key
      }).then(data => {
        this.$store.state.loading = false
        window.location.href = data
      }).catch((error) => {
        this.$store.state.loading = false
        this.errors = Methods.parseHtmlResponseError(this.$router, error)
      })
    }
  },
  watch : {
    '$i18n.locale' () {
      this.getNewNotices()
    }
  }
}
</script>
<style lang="scss">
  .notice-body {
    line-break: anywhere;
    img {
      max-width: 100%;
    }
  }
</style>
<style scoped lang="scss">
  .info-box-notice {
    .item-tag {
      cursor: default;
      color: black;
    }
    .info-item {
      @media only screen and (min-width: 768px) {
        max-height: 420px;
        overflow: auto;
      }
    }
  }
</style>

<style scoped src="@/assets/css/home.css">
</style>
<style scoped src="@/assets/css/set.css">
</style>
<style scoped src="@/assets/css/margin.css">
</style>
<style scoped src="@/assets/css/layout.css">
</style>
<style scoped src="@/assets/css/parts.css">
</style>
