<template>
<div class="machine-box-new">
  <h2><span>{{$t("TOP_PAGE_AUCTION_NEW_ITEMS_VIEW_HEADER")}}</span><a @click="$routerGo($define.PAGE_NAMES.STOCK_LIST_PAGE_NAME)">{{$t("TOP_PAGE_AUCTION_NEW_ITEMS_BUTTON_LABEL")}}<img src="@/assets/img/common/ic_arrow_white.svg" alt="矢印"></a></h2>
  <div class="matchH machine-item">
    <ul>
      <li v-for="item in items" :key="item.exhibition_item_no">
        <a @click="$routerGo($define.PAGE_NAMES.STOCK_DETAIL_PAGE_NAME, item.exhibition_item_no, {filter : encodeURIComponent(JSON.stringify(filter))})">
          <figure>
            <img v-if="item.image && item.image.length > 0 && item.image.find(file => !file.postar_file_path && file.file_path)"  :src="'/'+encodeURIComponent(item.image.find(file => !file.postar_file_path && file.file_path).file_path)"  alt="">
            <img v-else src="@/assets/img/no_photo.jpg" alt="">
          </figure>
          <dl>
            <dt>{{item.free_field.MAKER ? item.free_field.MAKER : ''}}</dt>
            <dd>{{$t("TOP_PAGE_ITEM_MODEL_LABEL")}}：{{item.free_field.MODEL ? item.free_field.MODEL : ''}}</dd>
            <dd>{{$t("TOP_PAGE_ITEM_MODEL_SERIAL_NUMBER_LABEL")}}：{{item.free_field.ACTSERNO ? item.free_field.ACTSERNO : ''}}</dd>
            <dd>{{$t("TOP_PAGE_ITEM_MODEL_YEAR_LABEL")}}：{{item.free_field.YYYY ? item.free_field.YYYY : ''}}</dd>
          </dl>
          <p class="price">
            {{$t("TOP_PAGE_PRICE_CURRENCY_MARK")}}
            <span class="member-only">{{(item.free_field && item.free_field.SHCR === 'C') ? 'ASK' : (item.attention_info && item.price_display_flag == 0 ? 'ASK' : item.free_field.NEWSPRC.numberString())}}</span>
          </p>
        </a>
      </li>
    </ul>
  </div>
  <div class="machine-btn">
    <a @click="$routerGo($define.PAGE_NAMES.STOCK_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: false}))})">{{$t("TOP_PAGE_INVENTORY_MACHINE_LIST_BUTTON_LABEL")}}<img src="@/assets/img/common/ic_arrow_white.svg" alt="矢印"></a>
    <a @click="$routerGo($define.PAGE_NAMES.STOCK_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: true}))})">{{$t("TOP_PAGE_LIST_OF_CRANE_STOCK_MACHINES_BUTTON_LABEL")}}<img src="@/assets/img/common/ic_arrow_white.svg" alt="矢印"></a>
  </div>
</div>
</template>

<script>
import Methods from '@/api/methods'
import Filter from '../ItemList/Filter.js'
export default {
  props: {
    parent: {
      type: Object,
      default: Object
    },
    search: {
      type: Function,
      default: Object
    }
  },
  mixins: [Filter],
  data() {
    return {
      extraFilter: {
        initLimit: 12,
        newStock: true
      }
    }
  }
}
</script>

<style scoped src="@/assets/css/home.css">
</style>
<style scoped src="@/assets/css/set.css">
</style>
<style scoped src="@/assets/css/margin.css">
</style>
<style scoped src="@/assets/css/layout.css">
</style>
<style scoped src="@/assets/css/parts.css">
</style>
