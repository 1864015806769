<template>
<v-main id="main">
  <div id="pNav" class="bgLGray">
    <ul>
      <li><a @click="$routerGo($define.PAGE_NAMES.TOP_PAGE_NAME)">TOP</a></li>
      <li v-if="$route.name==$define.PAGE_NAMES.BID_HISTORY_PAGE_NAME">{{$t("MY_PAGE_HEADER_BID_HISTORY_TEXT")}}</li>
      <li v-if="$route.name==$define.PAGE_NAMES.CHANGE_INFO_PAGE_NAME">{{$t("MY_PAGE_HEADER_CHANGE_INFO_TEXT")}}</li>
    </ul>
  </div>
  <Header />
  <router-view/>
</v-main>
</template>

<script>
import Header from '../components/MyPage/Header';
export default {
  components : {
    Header
  }
}
</script>
<style scoped src="@/assets/css/set.css">
</style>
<style scoped src="@/assets/css/layout.css">
</style>
