<template>
<main id="main" class="reminder">
  <div class="wrap1160">
    <div class="remind-msg">
      <p class="txtCoCor remind-txt-att" v-html="$t('REISSUE_PASSWORD_EXPLANATION_MESSAGE')"></p>
      <p class="mt15" v-html="$t('REISSUE_PASSWORD_CAUTION_MESSAGE')"></p>
    </div>
    <section id="login-form" class="bgLGray">
      <form>
        <table class="tbl-login">
          <tbody>
            <tr>
              <th>
                {{$t('REISSUE_PASSWORD_FORM_ID_LABEL')}}
              </th>
              <td>
                <input type="text" v-bind:class="{'ime-dis': true, 'err': errors.id}" v-model="model.id" required ref="id">
                <p class="err-txt">{{errors.id}}</p>
              </td>
            </tr>
            <tr>
              <th>
                {{$t('REISSUE_PASSWORD_FORM_EMAIL_LABEL')}}
              </th>
              <td>
                <input type="email" v-bind:class="{'ime-dis': true, 'err': errors.email}" v-model="model.email" required ref="email">
                <p class="err-txt">{{errors.email}}</p>
              </td>
            </tr>
            <tr>
              <th>
                {{$t('REISSUE_PASSWORD_FORM_CONFIRM_EMAIL_LABEL')}}
              </th>
              <td>
                <input type="email" v-bind:class="{'ime-dis': true, 'err': errors.confirmEmail}" v-model="model.confirmEmail" required ref="confirmEmail">
                <p class="err-txt">{{errors.confirmEmail}}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="id-pass-err">
          <p class="err-txt">{{errors.errorMessage}}</p>
        </div>
        <div class="btn-form">
          <input type="button" id="sbm-login" :value="$t('REISSUE_PASSWORD_FORM_SUBMIT_LABEL')" @click="reissuePassword">
        </div>
      </form>
    </section>
  </div>
</main>
</template>

<script>
import Methods from '@/api/methods'
export default {
  props: {
    parent: {
      type: Object,
      default: Object
    }
  },
  data() {
    return {
      scripts: [
      ],
      model: {},
      errors: {}
    }
  },
  methods: {
    reissuePassword () {
      this.errors = {}
      if (this.model.id && this.model.email !==  this.model.confirmEmail) {
        this.errors.confirmEmail = this.$t('MAIL_CONFIRM_ERROR_MESSAGE')
        return
      }
      this.$store.state.loading = true
      const params = {
        id: this.model.id,
        email: this.model.email
      }
      Methods.apiExcute('reissue-password', this.$i18n.locale, params).then(data => {
        this.$store.state.loading = false
        this.parent.completedFlag = true
      }).catch(error => {
        this.$store.state.loading = false
        this.errors = Methods.parseHtmlResponseError(this.$router, error)
      })
    }
  },
  mounted () {
    this.scripts.forEach(script => {
      let tag = document.createElement('script')
      tag.setAttribute('src', script)
      document.head.appendChild(tag)
    })
  },
  watch: {
    errors(value) {
      const keys = Object.keys(value)
      if (keys.length > 0 && this.$refs[keys[0]]) {
        this.$refs[keys[0]].focus()
      }
    }
  }
}
</script>

<style scoped src="@/assets/css/login.css">
</style>
<style scoped src="@/assets/css/set.css">
</style>
<style scoped src="@/assets/css/margin.css">
</style>
<style scoped src="@/assets/css/layout.css">
</style>
<style scoped src="@/assets/css/parts.css">
</style>
<style scoped lang="scss">
  .tbl-login {
    p.err-txt {
      max-width: 280px !important;
    }
  }
</style>
