<template>
<main id="main">
  <div class="wrap1160">
    <section id="entry-form" class="bgLGray">
      <form>
        <p class="entry-form-info" v-html="$t('MEMBER_REQUEST_FORM_ENTRY_FORM_INFO_MESSAGE')"></p>
        <table class="tbl-entry">
          <tbody>
            <tr>
              <th>ID</th>
              <td>
                <input type="text" class="iptW-M disabled" required v-model="parent.memberId" disabled>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_COUNTRY_LABEL')"></th>
              <td>
                <div class="select-style">
                  <select required v-model="parent.registerData.countryCode" v-bind:class="{'err': errors.countryCode, 'disabled': true}" ref="countryCode" disabled>
                    <option v-for="constant in parent.constants.filter(constant => constant.key_string == 'COUNTRY_CODE')" :value="constant.value1" :label="constant.value2" selected="selected" :key="constant.value1"></option>
                  </select>
                  <div class="error-msg">
                    <p class="err-txt">{{errors.countryCode}}</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_COMPANY_NAME_LABEL')"></th>
              <td>
                <input type="text" class="iptW-M" required v-model="parent.registerData.companyName" v-bind:class="{'err': errors.companyName}" @keydown="errors.companyName=null" ref="companyName">
                <div class="error-msg">
                  <p class="err-txt">{{errors.companyName}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_REPRESENTATIVE_DIRECTOR_LABEL')"></th>
              <td>
                <input type="text" class="iptW-M" v-model="parent.registerData.representativeDirector" v-bind:class="{'err': errors.representativeDirector}" @keydown="errors.representativeDirector=null" ref="representativeName">
                <div class="error-msg">
                  <p class="err-txt">{{errors.representativeDirector}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_BRANCH_OFFICE_LABEL')"></th>
              <td>
                <input type="text" class="iptW-M" v-model="parent.registerData.branchOffice" v-bind:class="{'err': errors.branchOffice}" @keydown="errors.branchOffice=null" ref="branchOffice">
                <div class="error-msg">
                  <p class="err-txt">{{errors.branchOffice}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_USER_NAME_LABEL')"></th>
              <td>
                <div class="ipt-wrap">
                  <input type="text" class="iptW-M" required v-model="parent.registerData.emailUserName" v-bind:class="{'err': errors.emailUserName}" @keydown="errors.emailUserName=null" ref="name">
                  <div class="error-msg">
                    <div class="error-center">
                      <span class="ipt-rule" v-html="$t('MEMBER_REQUEST_FORM_FULL_WIDTH_LABEL')"></span>
                      <p class="err-txt">{{errors.emailUserName}}</p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>{{$t('MEMBER_REQUEST_FORM_POST_CODE_LABEL')}}</th>
              <td>
                <div class="ipt-wrap">
                  <input type="text" class="ime-dis iptW-S disabled" v-model="parent.registerData.postCode" @keydown="errors.postCode=null" required v-bind:class="{'err': errors.postCode}" ref="postCode" disabled>
                </div>
              </td>
            </tr>
            <tr v-if="parent.registerData.countryCode === 'JP'">
              <th>{{$t('MEMBER_REQUEST_FORM_PREFECTURES_LABEL')}}</th>
              <td>
                <input type="text" class="iptW-M disabled" required v-model="parent.registerData.state" v-bind:class="{'err': errors.state}" @keydown="errors.state=null" ref="state" disabled>
                <div class="error-msg">
                  <p class="err-txt">{{errors.state}}</p>
                </div>
              </td>
            </tr>
            <tr v-if="parent.registerData.countryCode === 'JP'">
              <th>{{$t('MEMBER_REQUEST_FORM_MUNICIPALITIES_LABEL')}}</th>
              <td>
                <input type="text" class="iptW-M disabled" required v-model="parent.registerData.city" v-bind:class="{'err': errors.city}" @keydown="errors.city=null" ref="city" disabled>
                <div class="error-msg">
                  <p class="err-txt">{{errors.city}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <th>{{parent.registerData.countryCode === 'JP' ? $t('MEMBER_REQUEST_FORM_LOCATION_LABEL') : $t('MEMBER_REQUEST_FORM_LOCATION_LABEL_EN')}}</th>
              <td>
                <input type="text" class="iptW-M disabled" :placeholder="$t('MEMBER_REQUEST_FORM_LOCATION_PLACEHOLDER')" required v-model="parent.registerData.address" v-bind:class="{'err': errors.address}" @keydown="errors.address=null" ref="address" disabled>
                <div class="error-msg">
                  <p class="err-txt">{{errors.address}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_TEL_LABEL')"></th>
              <td>
                <div class="ipt-wrap">
                  <input type="text" class="ime-dis iptW-M" :placeholder="$t('MEMBER_REQUEST_FORM_PHONE_NUMBER_PLACEHOLDER')" required v-model="parent.registerData.tel" v-bind:class="{'err': errors.tel}" @keydown="errors.tel=null" ref="tel">
                  <div class="error-msg">
                    <div class="error-center">
                      <span class="ipt-rule" v-html="$t('MEMBER_REQUEST_FORM_FULL_WIDTH_NUMBERS_LABEL')"></span>
                      <p class="err-txt">{{errors.tel}}</p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_FAX_LABEL')"></th>
              <td>
                <div class="ipt-wrap">
                  <input type="text" class="ime-dis iptW-M" :placeholder="$t('MEMBER_REQUEST_FORM_FAX_NUMBER_PLACEHOLDER')" v-model="parent.registerData.fax" v-bind:class="{'err': errors.fax}" @keydown="errors.fax=null" ref="fax">
                  <div class="error-msg">
                    <div class="error-center">
                      <span class="ipt-rule" v-html="$t('MEMBER_REQUEST_FORM_FULL_WIDTH_NUMBERS_LABEL')"></span>
                      <p class="err-txt">{{errors.fax}}</p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_EMAIL_LABEL')"></th>
              <td>
                <div class="ipt-wrap">
                  <input type="email" class="ime-dis iptW-M" required v-model="parent.registerData.email" v-bind:class="{'err': errors.email}" @keydown="errors.email=null" ref="email">
                  <div class="error-msg">
                    <div class="error-center">
                      <span class="ipt-rule" v-html="$t('MEMBER_REQUEST_FORM_FULL_WIDTH_ALPHANUMERICAL_LABEL')"></span>
                      <p class="err-txt">{{errors.email}}</p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CURRENT_PASSWORD_LABEL')"></th>
              <td>
                <input :type="currentPasswordType" class="ime-dis iptW-M password" required v-model="parent.registerData.currentPassword" v-bind:class="{'err': errors.currentPassword}" @keydown="errors.currentPassword=null" ref="currentPassword" autocomplete="new-password">
                <span @click="showHidePassword(1)" class="show-password"><Icon :icon="currentPasswordType === 'password' ? 'akar-icons:eye' : 'akar-icons:eye-slashed'" style="vertical-align: middle;"/></span>
                <p class="err-txt">{{errors.currentPassword}}</p>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_PASSWORD_LABEL')"></th>
              <td>
                <input :type="passwordType" class="ime-dis iptW-M password" required v-model="parent.registerData.password" v-bind:class="{'err': errors.password}" @keydown="errors.password=null" ref="password" autocomplete="new-password">
                <span @click="showHidePassword(2)" class="show-password"><Icon :icon="passwordType === 'password' ? 'akar-icons:eye' : 'akar-icons:eye-slashed'" style="vertical-align: middle;"/></span>
                <div class="error-msg">
                  <p class="err-txt">{{errors.password}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_CONFIRM_PASSWORD_LABEL')"></th>
              <td>
                <input :type="passwordConfirmType" class="ime-dis iptW-M password" required v-model="parent.confirmPassword" v-bind:class="{'err': errors.confirmPassword}" @keydown="errors.confirmPassword=null" ref="confirmPassword" autocomplete="new-password">
                <span @click="showHidePassword(3)" class="show-password"><Icon :icon="passwordConfirmType === 'password' ? 'akar-icons:eye' : 'akar-icons:eye-slashed'" style="vertical-align: middle;"/></span>
                <div class="error-msg">
                  <p class="err-txt">{{errors.confirmPassword}}</p>
                </div>
              </td>
            </tr>
            <br/>
            <tr>
              <th v-html="$t('MEMBER_EDIT_FORM_EXPORT_FILE_LABEL')"></th>
              <td>
                <p>{{$t('MEMBER_EDIT_FORM_FILE_CHANGING_LABEL')}}</p>
              </td>
            </tr>
            <br/>
            <tr>
              <th v-html="$t('MEMBER_EDIT_FORM_ANTIQUE_FILE_LABEL')"></th>
              <td>
                <p>{{$t('MEMBER_EDIT_FORM_FILE_CHANGING_LABEL')}}</p>
              </td>
            </tr>
            <br/>
            <tr>
              <th v-html="$t('MEMBER_EDIT_FORM_NAME_CARD_FILE_LABEL')"></th>
              <td>
                <p>{{$t('MEMBER_EDIT_FORM_FILE_CHANGING_LABEL')}}</p>
              </td>
            </tr>
            <br/>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_AGREE_LABEL')"></th>
              <td>
                <label>
                  <input name="rule" v-model="checkBox.rule" type="checkbox" class="checkbox-input" required>
                  <span class="checkbox-parts" v-html="$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL')"></span>
                </label>
              </td>
            </tr>
            <tr class="att-use">
              <th>&nbsp;</th>
              <td>
                <p class="privacy-link">
                  {{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE1')}}
                  <a @click="$routerGoNewWindow($define.PAGE_NAMES.PRIVACY_PAGE_NAME, null, {lang: $i18n.locale})">{{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE2')}}</a>
                  {{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE3')}}
                </p>
              </td>
            </tr>

            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_MEMBERSHIP_AGREE_LABEL')"></th>
              <td>
                <label>
                  <input name="membership" v-model="checkBox.membership" type="checkbox" class="checkbox-input" required>
                  <span class="checkbox-parts" v-html="$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL')"></span>
                </label>
              </td>
            </tr>
            <tr class="att-use">
              <th>&nbsp;</th>
              <td>
                <p class="privacy-link">
                  {{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE1')}}
                  <a
                    @click="goToPdfViewer(parent.membershipTerms.file_url)"
                  >{{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE2_1')}}</a>
                  {{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE3')}}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="btn-form">
          <input type="button" style="background-color:red" :value="$t('MEMBER_REQUEST_FORM_WITHDRAW_LABEL')" @click="withdrawMemberComfirmDialog=true">
          <input type="button" id="sbm-login" :value="$t('MEMBER_REQUEST_FORM_SUBMIT_LABEL')" :disabled="isButtonDisabled" @click="validate">
        </div>
      </form>
    </section>
  </div>
  <v-dialog v-model="withdrawMemberComfirmDialog" max-width="400">
      <v-card>
        <v-card-title class="border-bottom">
          <div>{{$t("CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_HEADER_TEXT")}}</div>
        </v-card-title>
        <v-card-text class="border-bottom mt-5">
          <p class="text-center">{{$t("CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_MESSAGE_TEXT")}}</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="withdrawMemberComfirmDialog = false" color="red" class="white--text">{{$t("CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_CANCLE_LABEL")}}</v-btn>
          <v-btn @click="withdrawMember" color="#01a7ac" class="white--text">{{$t("CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_OK_LABEL")}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</main>
</template>

<script>
import Methods from '@/api/methods';
import commonMixin from '@/common/commonMixin';
import {Icon} from '@iconify/vue2';
import RegisterLogic from '../RegisterMember/RegisterLogic.js';
export default {
  components : {
    Icon
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    }
  },
  data() {
    return {
      scripts                     : [],
      errors                      : {},
      withdrawMemberComfirmDialog : false,
    }
  },
  mixins  : [RegisterLogic, commonMixin],
  methods : {
    request(params) {
      return Methods.apiExcute('private/change-member-info', this.$i18n.locale, params)
    },
    withdrawMember() {
      this.$store.state.loading = true
      Methods.apiExcute('private/withdraw-member', this.$i18n.locale).then(data => {
        this.$store.state.loading = false
        this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN)
        this.$routerGo(this.$define.PAGE_NAMES.LOGIN_PAGE_NAME)
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    extraValidate() {
      if (this.parent.registerData.password && this.parent.registerData.password !== this.parent.confirmPassword) {
        this.errors.confirmPassword = this.$t('PASSWORD_CONFIRM_ERROR_MESSAGE')
      }
      if (this.parent.registerData.password && !this.parent.registerData.currentPassword) {
        this.errors.currentPassword = this.$t('CURRENT_PASSWORD_ERROR_MESSAGE')
      }
    }
  },
  computed : {
    isButtonDisabled() {
      return !this.checkBox.rule || !this.checkBox.membership
    }
  }
}
</script>

<style scoped lang="scss">

  @media only screen and (min-width: 768px) {
    p.err-txt {
      position: absolute;
      top: 50%;
      left: 450px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
      max-width: 290px;
    }
  }
  @media only screen and (max-width: 767px) {
    p.err-txt {
        position: static;
        -webkit-transform: none;
        transform: none;
        max-width: 100%;
        margin-top: 5px;
    }
  }

  .with-int-no {
    width: 305px !important;
  }
  select.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  label.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  .bold-text {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
  .disabled {
    background-color: #c4c4c4;
  }
  .border-bottom {
    border-bottom: 1px solid #d9d9d9;
  }
  .text-center {
    text-align: center;
  }
</style>
<style lang="scss">
  .remodal-wrapper:after {
    vertical-align: middle;
  }
  .fontB {
    font-weight: 700!important;
  }
  p.kome {
    margin: 0 !important;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    font-style: normal;
    font-weight: normal;
    text-align: left;
    text-indent: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
</style>

<style scoped src="@/assets/css/login.css">
</style>
<style scoped src="@/assets/css/set.css">
</style>
<style scoped src="@/assets/css/margin.css">
</style>
<style scoped src="@/assets/css/layout.css">
</style>
<style scoped src="@/assets/css/parts.css">
</style>
<style src="@/assets/css/entry.css">
</style>
<style src="@/assets/css/remodal.css">
</style>
<style scoped src="@/assets/css/remodal-default-theme.css">
</style>
