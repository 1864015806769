import { render, staticRenderFns } from "./NewStocks.vue?vue&type=template&id=c8da9760&scoped=true"
import script from "./NewStocks.vue?vue&type=script&lang=js"
export * from "./NewStocks.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/home.css?vue&type=style&index=0&id=c8da9760&prod&scoped=true&lang=css&external"
import style1 from "@/assets/css/set.css?vue&type=style&index=1&id=c8da9760&prod&scoped=true&lang=css&external"
import style2 from "@/assets/css/margin.css?vue&type=style&index=2&id=c8da9760&prod&scoped=true&lang=css&external"
import style3 from "@/assets/css/layout.css?vue&type=style&index=3&id=c8da9760&prod&scoped=true&lang=css&external"
import style4 from "@/assets/css/parts.css?vue&type=style&index=4&id=c8da9760&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8da9760",
  null
  
)

export default component.exports