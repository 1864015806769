export default {
  data() {
    return {
      pdfViewerUrl : '/lib/pdfjs-2.10.377-dist/web/viewer.html?file=/'
    }
  },
  methods : {
    goToPdfViewer(url) {
      if (url) {
        window.open(this.pdfViewerUrl + url, '_blank')
      }
    }
  }
}
