<template>
<div>
  <Completion v-if="completedFlag" :parent="$data" />
  <InputForm v-else :parent="$data" />
</div>
</template>

<script>
import InputForm from '../components/ReissuePassword/InputForm'
import Completion from '../components/ReissuePassword/Completion'
import Methods from '@/api/methods'
export default {
  components: {
    InputForm,
    Completion
  },
  data() {
    return {
      scripts: [
      ],
      completedFlag: false
    }
  },
  methods: {
  },
  mounted () {
    Methods.addExternalScirpts(this.scripts)
  }
}
</script>
