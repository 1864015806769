import { render, staticRenderFns } from "./MyPage.vue?vue&type=template&id=4aedf4c7&scoped=true"
import script from "./MyPage.vue?vue&type=script&lang=js"
export * from "./MyPage.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/set.css?vue&type=style&index=0&id=4aedf4c7&prod&scoped=true&lang=css&external"
import style1 from "@/assets/css/layout.css?vue&type=style&index=1&id=4aedf4c7&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4aedf4c7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VMain})
