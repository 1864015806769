<template>
<div class="wrap1160">
  <div id="search">
    <div class="search_box">
      <h2>{{$t(parent.nameTag)}}</h2>
      <form v-on:submit.prevent="searchItems(null)">
        <div class="search-area">
          <div class="search-area-block">
            <dl>
              <dt>{{$t("ITEM_SEARCH_KEYWORD_LABEL")}}</dt>
              <dd><input type="text" style="background-color: white;" v-model="filter.searchKey" :placeholder="$t('ITEM_SEARCH_KEYWORD_PLACEHOLDER')"></dd>
            </dl>
          </div>
          <div class="search-area-block">
            <dl>
              <dt>{{$t("ITEM_SEARCH_INVENTORY_AREA_LABEL")}}</dt>
              <dd>
                <ul  v-if="areaConstants.length > 0" class="pst-area">
                  <li v-for="(area, index) in areaConstants" :key="index">
                    <label>
                      <input type="checkbox" class="checkbox-input" v-model="filter.areas[index]">
                      <span class="checkbox-parts">{{area.value2}}</span>
                    </label>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div class="search-area-block">
            <dl class="aliTop">
              <dt>{{$t("ITEM_SEARCH_CATEGORY_LABEL")}}</dt>
              <dd>
                <ul class="pst-cate">
                  <li v-for="(category, index) in categoryConstants" :key="index" :class="{'active': filter.category[index]}">
                    <label>
                      <input type="checkbox" class="checkbox-input" v-model="filter.category[index]" @change="categoryChange(index)">
                      <span class="checkbox-parts">{{category.value2}}</span>
                    </label>
                    <figure><img v-if="category.file_url" :src="'/'+ category.file_url"></figure>
                    <p v-if="categoryResultCount" class="hit">{{categoryResultCount[index] == '0' ? '' : categoryResultCount[index] + $t('ITEM_SEARCH_CATEGORY_SEARCH_RESULTS_NUMBER_LABEL')}}</p>
                    <div v-if="category.value3" class="weight matchH">
                      <ul>
                        <li v-for="option in category.value3.split(',')" :key="option">
                          <label>
                            <input type="checkbox" class="checkbox-input" :value="option" v-model="filter.categoryExtension[index]" :id="option" @change="categoryExtensionChange">
                            <span class="checkbox-parts">{{option}}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div class="search-area-block">
            <dl class="half">
              <dt>{{$t("ITEM_SEARCH_MODEL_YEAR_LABEL")}}</dt>
              <dd>
                <div class="pst-year">
                  <div class="select-style">
                    <select v-model="filter.startYear">
                      <option :value="null"></option>
                      <option v-for="(year, index) in selectYears" :value="year" :key="index">{{year}}</option>
                    </select>
                  </div>
                  <span>～</span>
                  <div class="select-style">
                    <select v-model="filter.endYear">
                      <option :value="null"></option>
                      <option v-for="(year, index) in selectYears.filter(year => !filter.startYear || year >= filter.startYear)" :value="year" :key="index">{{year}}</option>
                    </select>
                  </div>
                </div>
              </dd>
            </dl>
            <dl class="half" v-if="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!=null">
              <dt>{{$t("ITEM_SEARCH_PRICE_LABEL")}}</dt>
              <dd>
                <div class="pst-year">
                  <div class="select-style">
                    <select v-model="filter.startPrice">
                      <option :value="null"></option>
                      <option v-for="(price, index) in selectPrice" :value="price" :key="index">{{price}}{{$t('ITEM_SEARCH_PRICE_UNIT')}}</option>
                    </select>
                  </div>
                  <span>～</span>
                  <div class="select-style">
                    <select v-model="filter.endPrice">
                      <option :value="null"></option>
                      <option v-for="(price, index) in selectPrice.filter(price => !filter.startPrice || price > filter.startPrice)" :value="price" :key="index">{{price}}万</option>
                    </select>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
          <div class="search-area-block">
            <dl>
              <dd>
                <ul class="pst-cek">
                  <li v-if="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!=null">
                    <label>
                      <input type="checkbox" class="checkbox-input" v-model="filter.favorite">
                      <span class="checkbox-parts">{{$t("ITEM_SEARCH_FAVORITE_LABEL")}}</span>
                    </label>
                  </li>
                  <li v-if="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!=null && $route.name!=$define.PAGE_NAMES.STOCK_LIST_PAGE_NAME">
                    <label>
                      <input type="checkbox" class="checkbox-input" v-model="filter.bidding">
                      <span class="checkbox-parts">{{$t("ITEM_SEARCH_BIDDING_LABEL")}}</span>
                    </label>
                  </li>
                  <li v-if="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!=null && $route.name==$define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME">
                    <label>
                      <input type="checkbox" class="checkbox-input" v-model="filter.exceedingLowestPrice">
                      <span class="checkbox-parts">{{$t("ITEM_SEARCH_EXCEEDING_THE_LOWEST_BID_LABEL")}}</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="checkbox" class="checkbox-input" v-model="filter.unSoldOut">
                      <span class="checkbox-parts">{{$t("ITEM_SEARCH_SOLD_OUT_LABEL")}}</span>
                    </label>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <a class="btnBsc-Black btn-search" @click="filter.initLimit = filter.limit; searchItems(null);">{{$t("ITEM_SEARCH_SEARCH_BUTTON_LABEL")}}</a>
        </div>
      </form>
    </div>

    <div class="search_result">
      <dl>
        <dt>{{$t("ITEM_SEARCH_CONDITIONS_LABEL")}}</dt>
        <dd>
          <span v-for="(row, index) in getFiltered(filteredObject)" :key="index">{{row}}</span>
        </dd>
      </dl>
      <dl>
        <dt>{{$t("ITEM_SEARCH_SEARCH_RESULTS_LABEL")}}</dt>
        <dd>{{itemsCount}}{{$t("ITEM_SEARCH_SEARCH_RESULTS_NUMBER_SUBJECT_LABEL")}}</dd>
      </dl>
    </div>
  </div>

  <div id="result">
    <div class="sort">
      <div class="btnArea">
        <a class="btn-dl" v-if="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!=null && items.length > 0" @click="downloadItemsCsv">{{$t("ITEM_SEARCH_PRINT_ITEMS_CSV_BUTTON_LABEL")}}<img src="@/assets/img/common/ic_dl_bgreen.svg" alt=""></a>
        <a class="btn-all" v-if="showBulkBiddingButton" @click="bulkItems=items.filter(item => item.changed);bulkBiddingDialog=true">{{$t("ITEM_SEARCH_BULK_BIDDING_BUTTON_LABEL")}}<img src="@/assets/img/common/ic_arrow_white.svg" alt=""></a>
      </div>

      <form v-on:submit.prevent="">
        <div class="btnSort">
          <div class="btn_sort-price" v-if="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!=null">
            <p v-if="$route.name==$define.PAGE_NAMES.TENDER_LIST_PAGE_NAME">{{$t("DETAIL_LOWEST_BID_ACCEPT_PRICE_LABEL")}}</p>
            <p v-else>{{$t("ITEM_SEARCH_CURRENT_PRICE_SORT_LABEL")}}</p>
            <button :class="{'active' : filter.currentPriceSort == 'asc'}" @click="filter.currentPriceSort = 'asc'; updateFilter()">▲</button>
            <button :class="{'active' : filter.currentPriceSort == 'desc'}" @click="filter.currentPriceSort = 'desc'; updateFilter()">▼</button>
          </div>
          <div class="btn_sort-nmb">
            <p>{{$t("ITEM_SEARCH_RESULTS_LIMIT_LABEL")}}</p>
            <button v-for="per in limitPerPages" :class="{'active' : filter.limit == per}" @click="filter.limit = per; updateFilter()">{{per}}{{$t("ITEM_SEARCH_SEARCH_RESULTS_SUBJECT_LABEL")}}</button>
          </div>
        </div>
      </form>
    </div>

    <ul class="machine_list">
      <ItemRow
        v-for="(item, index) in items"
        :item="item"
        :parent="$data"
        :grandfather="parent"
        :showErrorsDialog="showErrorsDialog"
        :updateViewAfterBid="updateViewAfterBid"
        :errorMessages="[]"
        :favorite="favorite"
        :key="item.exhibition_item_no"
        @bidConfirm="openBidConfirmDialog"
      />
    </ul>

    <a v-if="isMoreLimit" class="btnBsc-Black btn-more" @click="searchItems(items.map(item => item.exhibition_item_no))">{{$t('MY_PAGE_SHOW_MORE_BUTTON_LABEL')}}</a>

    <div class="int-link" v-if="$route.name==$define.PAGE_NAMES.STOCK_LIST_PAGE_NAME&&showStockCraneFlag">
      <a href="https://www.kobelcocm-global.com/pickup/kisco-used/" target="_blank"><img src="@/assets/img/stock/bnr_singapore.png" alt="USED STOCK CRANE in SINGAPORE"></a>
      <a href="https://www.kobelco-europe.com/used-crane-services/" target="_blank"><img src="@/assets/img/stock/bnr_europe.png" alt="USED STOCK CRANEin EUROPE"></a>
      <a href="https://www.kobelco-mea.com/products/used/" target="_blank"><img src="@/assets/img/stock/bnr_middleeast.png" alt="USED STOCK CRANEin MIDDLE EAST"></a>
    </div>
  </div>
  <v-dialog v-model="errorsDialog" persistent content-class="error-dialog" style="z-index=5">
    <v-card>
      <v-divider></v-divider>
      <v-card-title>
        <div>入札エラー</div>
      </v-card-title>
      <v-card-text class="pt-3 overflow-y-auto">
        <v-row>
          <v-col v-for="(msg, index) in errors" :key="index"  cols="12" class="py-0 error--text subtitle-1">{{ msg }}</v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="indigo white--text" @click="errorsDialog=false">{{$t("ITEM_SEARCH_BULK_BIDDING_MODAL_CANCEL_BUTTON_LABEL")}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="bulkBiddingDialog" persistent content-class="bulk-bidding-dialog" style="z-index=5">
    <v-card>
      <v-divider></v-divider>
      <v-card-title>
        <div>{{$t("ITEM_SEARCH_BULK_BIDDING_MODAL_HEADER")}}</div>
      </v-card-title>
      <v-card-text class="pt-3 overflow-y-auto bulk-bidding-card-text">
        <ul class="machine_list">
          <ItemRow
            v-for="(item, index) in bulkItems"
            :name="'bulk_' + index"
            :item="item"
            :parent="$data"
            :grandfather="parent"
            :showErrorsDialog="showErrorsDialog"
            :bulkBidding="true"
            :removeBulkItemByItemNo="removeBulkItemByItemNo"
            :errorMessages="bulkMessages.filter(error => error.exhibition_item_no == item.exhibition_item_no)"
            :key="item.exhibition_item_no"
            :favorite="favorite"
          />
        </ul>
        <div class="bidP-enter">
          <button @click="bidBulk">{{$t("ITEM_SEARCH_BULK_BIDDING_MODAL_BID_BUTTON_LABEL")}}</button>
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <button @click="bulkBiddingDialog=false" class="remodal-close"></button>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="confirmBidDialog" persistent content-class="bulk-bidding-dialog" style="z-index=5">
    <v-card>
      <v-divider></v-divider>
      <v-card-title>
        <div>{{$t("ITEM_SEARCH_CONFIRM_BIDDING_MODAL_HEADER")}}</div>
      </v-card-title>
      <v-card-text class="pt-3 overflow-y-auto bulk-bidding-card-text">
        <ul class="machine_list">
          <ItemRow
            v-for="(item, index) in bidConfirmItems"
            :name="'bid_confirm_' + index"
            :item="item"
            :parent="$data"
            :grandfather="parent"
            :showErrorsDialog="showErrorsDialog"
            :confirmBidding="true"
            :bidConfirmSuccess="item.bidConfirmSuccess"
            :errorMessages="bidConfirmMessages.filter(error => error.exhibition_item_no == item.exhibition_item_no)"
            :key="item.exhibition_item_no"
            :favorite="favorite"
          />
        </ul>        
        <div class="bid-confirm-message"><span>{{$t('ITEM_ROW_BID_CONFIRM_MESSAGE')}}</span></div>
        <div class="bidP-enter">
          <button @click="bidConfirm">{{$t("ITEM_SEARCH_BULK_BIDDING_MODAL_BID_BUTTON_LABEL")}}</button>
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <button @click="closeBidConfirmDialog" class="remodal-close"></button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import Base from '@/common/base'
import ItemRow from './ItemRow'
import Filter from './Filter.js'
import WebSocket from './WebSocket.js'
import BidConfirm from './bidConfirm.js'
import Methods from '@/api/methods'
export default {
  components: {
    ItemRow
  },
  props: {
    parent: {
      type: Object,
      default: Object
    },
    search: {
      type: Function,
      default: Object
    },
    printItemsCsv: {
      type: Function,
      default: Object
    },
    favorite: {
      type: Function,
      default: Object
    }
  },
  mixins: [Filter, WebSocket, BidConfirm],
  data() {
    return {
      errorsDialog: false,
      bulkBiddingDialog: false,
      bulkItems: [],
      bulkMessages: [],
      showBulkBiddingButton: false,
      selectYears: Array.from({length: new Date().getFullYear() - 1900}, (value, index) => new Date().getFullYear() - index),
      selectPrice: Array.from({length: 10}, (value, index) => 100*(index+1))
    }
  },
  methods: {
    bidBulk() {
      const items = this.bulkItems.map(item => {
        return {
          exhibitionItemNo: item.exhibition_item_no,
          bidPrice: Base.localeString2Number(item.inputBidPrice) * item.bid_status.pitch_width
        }
      })
      this.bidItems(items).then(data => {
        this.bulkMessages = data.bidList.filter(bid => bid.errorMessage)
      }).catch((error) => {
        this.$store.state.loading = false
        Methods.parseHtmlResponseError(this.$router, error)
      })
    },
    downloadItemsCsv() {
      this.$store.state.loading = true
      const params = this.getSearchItemsRequestParams(this.filteredObject)
      this.printItemsCsv(params).then(data => {
        this.$store.state.loading = false
        location.href = data.url
      }).catch((error) => {
        this.$store.state.loading = false
        this.errors = Methods.parseHtmlResponseError(this.$router, error)
      })
    },
    categoryExtensionChange () {
      for (let index = 0; index < this.filter.category.length; index++) {
        this.filter.category[index] = this.filter.category[index] || this.filter.categoryExtension[index].filter(element => element).length > 0
      }
      this.filter = Object.assign({}, this.filter)
    },
    categoryChange (index) {
      if (!this.filter.category[index]) {
        this.filter.categoryExtension[index] = []
      }
      this.filter = Object.assign({}, this.filter)
    },
    updateFilter () {
      this.filter = Object.assign({}, this.filter)
    },
    showErrorsDialog(errors) {
      this.errors = errors
      this.errorsDialog = true
    },
    removeBulkItemByItemNo(itemNo) {
      this.bulkItems = this.bulkItems.filter(item => item.exhibition_item_no != itemNo)
      if (this.bulkItems.length === 0) {
        this.bulkBiddingDialog = false
      }
    }
  },
  watch: {
    '$i18n.locale' () {
      this.searchItems()
      this.getItemSearchConstants()
    },
    'filter.currentPriceSort' () {
      this.searchItems()
    },
    'filter.limit' () {
      this.searchItems()
    }
  }
}
</script>
<style src="@/assets/css/list.css">
</style>
<style scoped src="@/assets/css/set.css">
</style>
<style scoped src="@/assets/css/margin.css">
</style>
<style scoped src="@/assets/css/layout.css">
</style>
<style scoped src="@/assets/css/parts.css">
</style>
