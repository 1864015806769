import { render, staticRenderFns } from "./Register.vue?vue&type=template&id=573dd987&scoped=true"
import script from "./Register.vue?vue&type=script&lang=js"
export * from "./Register.vue?vue&type=script&lang=js"
import style0 from "./Register.vue?vue&type=style&index=0&id=573dd987&prod&scoped=true&lang=scss"
import style1 from "./Register.vue?vue&type=style&index=1&id=573dd987&prod&lang=scss"
import style2 from "@/assets/css/login.css?vue&type=style&index=2&id=573dd987&prod&scoped=true&lang=css&external"
import style3 from "@/assets/css/set.css?vue&type=style&index=3&id=573dd987&prod&scoped=true&lang=css&external"
import style4 from "@/assets/css/margin.css?vue&type=style&index=4&id=573dd987&prod&scoped=true&lang=css&external"
import style5 from "@/assets/css/layout.css?vue&type=style&index=5&id=573dd987&prod&scoped=true&lang=css&external"
import style6 from "@/assets/css/parts.css?vue&type=style&index=6&id=573dd987&prod&scoped=true&lang=css&external"
import style7 from "@/assets/css/entry.css?vue&type=style&index=7&prod&lang=css&external"
import style8 from "@/assets/css/remodal.css?vue&type=style&index=8&prod&lang=css&external"
import style9 from "@/assets/css/remodal-default-theme.css?vue&type=style&index=9&id=573dd987&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "573dd987",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VSpacer})
